import { LocationState,LocationActionType,LIST_LOCATION } from "./types";

const initState = {
    locations: []
}
const reducer = (state: LocationState=initState,action:LocationActionType)=>{
    switch(action.type){
        case LIST_LOCATION:
            return {
                ...state,
                locations: action.payload
            }
        default:
            return state;
    }
}

export default reducer;