/*
export const host = "http://localhost:4200";
export const apiPath:string = `${host}/api/v1`;
*/

//export const host = "http://202.28.95.5/registration/build";
export const host = "https://regist.md.kku.ac.th";
export const apiPath:string = `${host}/server_php/api`;
//export const clientPath: string = "/registration/build";

export const clientPath: string = "";
