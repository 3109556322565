import { CAREERPRIMARYLIST } from "../career-primary/types";

export interface CareerSecondary{
    id: number;
    code: string;
    name_eng: string;
    name_tha: string;
}

export interface CareerSecondaryState{
    careerSecondarys: CareerSecondary[]
}

export const CAREERSECONDARYLIST = "CAREERSECONDARYLIST";

interface ListAction{
    type: typeof CAREERSECONDARYLIST,
    payload: CareerSecondary[]
}

export type CareerSecondaryActionType = ListAction;