import { NationActionType, NationStateType, NATION_LIST_ACTION } from "./types";
const initState = {
    nations: [],
    nation: {}
}
const reducer = (state: NationStateType=initState,action: NationActionType) => {
    switch(action.type){
        case NATION_LIST_ACTION:
            return {
                ...state,
                nations: action.payload
            }
        default: return state;
    }
}

export default reducer;