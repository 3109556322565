export const AGREE_ACCEPT = "AGREE_ACCEPT";
export const AGREE_UNACCEPT = "AGREE_UNACCEPT";
export type Agreement = {
    accept: boolean
}
export type AgreeAction = {
    type: typeof AGREE_ACCEPT,
}

export type UnAgreeAction = {
    type: typeof AGREE_UNACCEPT
}

export type AgreeActionType = AgreeAction | UnAgreeAction;