import React, { ReactElement,useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Link, NavLink } from 'react-router-dom';
import { clientPath } from '../config/api';

import { Avatar, Typography } from '@material-ui/core';
import { orange, lightGreen } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import { agreeUnAcctptAction } from '../store/agreement/actions'
interface Props {
    children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            marginTop: theme.spacing(5),
            height: theme.spacing(14),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 25,
            padding: "10px 10px 10px 20px",
        },
        link: {
            textDecoration: "none"
        },
        paperMan: {
            marginTop: theme.spacing(5),
            padding: 20,
        },
        manualHead: {
            fontSize: 24,
            textAlign: "center",
            fontWeight: "bold"
        },
        manualList: {
            fontSize: 16,
            listStyleType: "decimal",
            marginBottom: 15,
            marginLeft: 15,
        },
        avatar: {
            backgroundColor: lightGreen[500],
            marginRight: 5,
        },
        banner: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            backgroundColor: orange[400],
            flexDirection: "column",

        }
    })
})

export default function Home({ children }: Props): ReactElement {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(agreeUnAcctptAction())
    },[])
    return (
        <>
            <div className="container max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">

                <div className="flex justify-center bg-green-100">
                    <p className="text-2xl 
                    border-b-2 
                    border-green-800
                    text-green-900 
                    ">ลงทะเบียนผู้ป่วยใหม่ออนไลน์</p>
                </div>

                <div className="flex flex-wrap justify-center mt-8">
                    <NavLink to={`${clientPath}/new-register`} className="relative bg-green-100 rounded-lg shadow-lg h-52 w-48 mx-2 hover:-mr-1">
                        <div className="flex w-48 h-10 rounded-t-lg justify-center bg-green-600 items-center">
                            <i className="far fa-file fa-2x text-white"></i>
                        </div>
                        <div className="p-2 flex items-center justify-center flex-col">
                            <p className="text-lg text-green-900 border-green-800 border-b-2">ทำบัตรใหม่</p>
                            <p className="text-lg text-green-900 mt-2">คนไข้ที่ยังไม่เคยมีประวัติ หรือไม่เคยเข้ารับการรักษา</p>
                        </div>
                        <div className="absolute right-0 bottom-0">
                            <i className="far fa-caret-square-right fa-2x text-green-500"></i>
                        </div>
                    </NavLink>{/** end box */}
                </div>
                <div className="flex flex-wrap justify-center mt-8">
                    <NavLink to={`${clientPath}/check-new-register`} className="relative bg-green-100 rounded-lg shadow-lg h-52 w-48 mx-2 hover:-mr-1">
                        <div className="flex w-48 h-10 rounded-t-lg justify-center bg-green-600 items-center">
                            <i className="far fa-check-circle fa-2x text-white"></i>
                        </div>
                        <div className="p-2 flex items-center justify-center flex-col">
                            <p className="text-lg text-green-900 border-green-800 border-b-2">สถานะการทำบัตรใหม่</p>
                            <p className="text-lg text-green-900 mt-2">ตรวจสอบการลงทะเบียน</p>
                        </div>
                        <div className="absolute right-0 bottom-0">
                            <i className="far fa-caret-square-right fa-2x text-green-500"></i>
                        </div>
                    </NavLink>
                </div>
                <div className="flex flex-wrap justify-center mt-8">
                    {/* <NavLink to={`${clientPath}/condition`} className="relative bg-green-100 rounded-lg shadow-lg h-52 w-48 mx-2 hover:-mr-1">
                        <div className="flex w-48 h-10 rounded-t-lg justify-center bg-green-600 items-center">
                            <i className="far fa-check-circle fa-2x text-white"></i>
                        </div>
                        <div className="p-2 flex items-center justify-center flex-col">
                            <p className="text-lg text-green-900 border-green-800 border-b-2">เงื่อนไขการใช้งาน</p>
                            <p className="text-lg text-green-900 mt-2">ขั้นตอนการใช้งานระบบ</p>
                        </div>
                        <div className="absolute right-0 bottom-0">
                            <i className="far fa-caret-square-right fa-2x text-green-500"></i>
                        </div>
                    </NavLink> */}

                    
                    <div className="relative bg-green-100 rounded-lg shadow-lg h-62 w-48 mx-2 hover:-mr-1">
                        <div className="flex w-48 h-10 rounded-t-lg justify-center bg-green-600 items-center">
                            <i className="far fa-check-circle fa-2x text-white"></i>
                        </div>
                        <div className="p-2 flex items-center justify-center flex-col">
                            <p className="text-lg text-green-900 border-green-800 border-b-2">คู่มือการใช้งาน</p>
                            <p className="text-lg text-green-900 mt-2">
                                <NavLink to={`${clientPath}/user-manual`}>ขั้นตอนการใช้งานระบบ(WEB)</NavLink>
                            </p>
                            {/* <p className="text-lg text-green-900 mt-2">
                                <a target="_blank" href="http://202.28.95.5/registration/server_php/files/1.pdf">ขั้นตอนการใช้งานระบบ(PDF)</a>
                            </p> */}
                            <p className="text-lg text-green-900 mt-2">
                            <a  target="_blank" href="https://youtu.be/hlPteHX_OBg">
                                ขั้นตอนการใช้งานระบบ(VIDEO)
                            </a>
                                </p>
                        </div>
                        <div className="absolute right-0 bottom-0">
                            <i className="far fa-caret-square-right fa-2x text-green-500"></i>
                        </div>
                    </div>

                    
                </div>
                {/* <div className="w-full mt-8 border-t-2 border-green-500"></div> */}

                {/* <div className="flex justify-center">
                    <p className="text-2xl 
                    border-b-2 
                    border-green-800
                    text-green-900
                    mt-4
                    ">ตรวจสอบสถานะการลงทะเบียนผู้ป่วยออนไลน์</p>
                </div> */}

                <div className="flex flex-wrap justify-center mt-8">
                    {/* <NavLink to={`${clientPath}/register`} className="relative bg-green-100 rounded-lg shadow-lg h-52 w-48 mx-2 hover:-mt-1">
                        <div className="flex w-48 h-10 rounded-t-lg justify-center bg-green-600 items-center">
                            <i className="far fa-bookmark fa-2x text-white"></i>
                        </div>
                        <div className="p-2 flex items-center justify-center flex-col">
                            <p className="text-lg text-green-900 border-green-800 border-b-2">ยืนยันการเข้าตรวจ</p>
                            <p className="text-lg text-green-900 mt-2">แบบฟอร์มลงทะเบียนยืนยันการเข้าตรวจ (Check in)</p>
                        </div>
                        <div className="absolute right-0 bottom-0">
                            <i className="far fa-caret-square-right fa-2x text-green-500"></i>
                        </div>
                    </NavLink> */}
                    {/** end box */}
                    {/* <NavLink to={`${clientPath}/check-register`} className="relative bg-green-100 rounded-lg shadow-lg h-52 w-48 mx-2 hover:-mt-1">
                        <div className="flex w-48 h-10 rounded-t-lg justify-center bg-green-600 items-center">
                            <i className="far fa-check-square fa-2x text-white"></i>
                        </div>
                        <div className="p-2 flex items-center justify-center flex-col">
                            <p className="text-lg text-green-900 border-green-800 border-b-2">สถานะยืนยันเข้าตรวจ</p>
                            <p className="text-lg text-green-900 mt-2">ตรวจสอบการลงทะเบียนยืนยันการเข้าตรวจ (Check in)</p>
                        </div>
                        <div className="absolute right-0 bottom-0">
                            <i className="far fa-caret-square-right fa-2x text-green-500"></i>
                        </div>
                    </NavLink> */}
                    {/** end box */}

                </div>
                {/* <div className="w-full mt-8 border-t-2 border-green-500"></div> */}



            </div>
        </>
    )
}
