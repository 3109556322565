export interface Nation{
    name_tha?: string
}

export interface NationStateType{
    nations: Nation[];
    nation: Nation
}

export const NATION_LIST_ACTION = "NATION_LIST_ACTION";

interface NationListAction{
    type: typeof NATION_LIST_ACTION;
    payload: Nation[];
}

export type NationActionType = NationListAction;