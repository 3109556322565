import { AgreeActionType,Agreement,AGREE_ACCEPT,AGREE_UNACCEPT } from './types'
const initReducer: Agreement = {
    accept: false
}
const reducer = (reducer = initReducer,action: AgreeActionType): Agreement =>{
    switch(action.type){
        case AGREE_ACCEPT:
            return {
                ...reducer,
                accept: true
            }
        case AGREE_UNACCEPT: 
            return {
                ...reducer,
                accept: false
            }
        default: 
            return reducer;

    }
}

export default reducer;

