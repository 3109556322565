
import { ADD_NEW_PATIENT, CLEAR_NEW_PATIENT, CLEAR_ONE_NEW_PATIENT, LIST_NEW_PATIENT, LIST_ONE_NEW_PATIENT, NewPatient, NewPatientActionType, NewPatientState, 
    SET_DATA_SEARCH_NEW_PATIENT, UPDATE_NEW_PATIENT } from "./types";


const initState = {
    newPatients: [],
    dataSearch: {},
    count: 0,
    newPatient: {
        
        id: "",
        hn: "",
        prename: "",
        fname: "",
        lname: "",
        lname_old: "",
        gender: "",
        birth_year: "",
        birth_month: "",
        birth_day: "",
        rid: "",
        passport: "",
        address: "",
        road: "",
        soi: "",
        moo: "",
        tambol: "",
        amper: "",
        province: "",
        post_code: "",
        mobile: "",
        statuses: "",
        educational: "",
        career: "",
        career_desc: "",
        blood_type: "",
        drug_allergy: "",
        nationality: "",
        race: "",
        religion: "",
        father_name: "",
        mother_name: "",
        spouse: "",
        emergency_fname: "",
        emergency_lname: "",
        emergency_call: "",
        emergency_relate: "",
        is_no_relatives: false,
        is_unconscious: false,
        relative_house_address: "",
        location_name: "",
        examination_date: new Date(),
        symptom: "",
        gen_code: "",
        right_treatment: "",
        right_treatment_desc: "",
        isNonThai: false,
        photo: "",
        photo2: "",
        active: "N"
    }
}
export const newPatientReducer = (state: NewPatientState= initState,action: NewPatientActionType)=>{
    switch(action.type){
        case ADD_NEW_PATIENT:
            let newState = {...state};
            newState.newPatients.push(action.payload);
            newState.newPatient = action.payload;
            return newState;
        case UPDATE_NEW_PATIENT:
            let newStateUpdate = {...state}
            newStateUpdate.newPatient=action.payload;

            const index = newStateUpdate.newPatients.findIndex(newPatient=>{
                return newPatient.id===action.payload.id;
            });

            newStateUpdate.newPatients[index] = action.payload;
            return newStateUpdate;
        case LIST_NEW_PATIENT:
            return {
                ...state,
                newPatients: [
                    ...state.newPatients,
                    ...action.payload.newPatients
                ]
                ,
                count: action.payload.count
            }
        case SET_DATA_SEARCH_NEW_PATIENT:
            return{
                ...state,
                dataSearch: action.payload
            }
        case LIST_ONE_NEW_PATIENT:
            
            return{
                ...state,
                newPatient: action.payload
            }
        case CLEAR_ONE_NEW_PATIENT:
            return{
                ...state,
                newPatient: initState.newPatient
            }
        case CLEAR_NEW_PATIENT:
            return{
                ...state,
                newPatients: initState.newPatients,
                count: 0
            }
        default:
            return state;
    }
}