import { PlaylistAddOutlined } from "@material-ui/icons"
import { LIST_PRENAME, PrenameActionType, PrenameState } from "./types"

const initState: PrenameState = {
    prenames: [],
    prename: {
        id: "",
        name: ""
    }
}
const reducer = (state=initState,action: PrenameActionType)=>{
    switch(action.type){
        case LIST_PRENAME:
            return {
                ...state,
                prenames: action.payload
            }
        default:
            return state
    }
}

export default reducer;