export interface Tambol{
    id?: number;
    code?: string;
    name_eng?: string;
    name_tha?: string;
}

export const TAMBOL_LIST = "TAMBOL_LIST";

export interface TambolState{
    tambols: Tambol[];
}

interface TambolListAction{
    type: typeof TAMBOL_LIST;
    payload: Tambol[]
}

export type TambolActionType = TambolListAction;