export interface Patient{
    id?: number;
    hn: string;
    rid: string;
    email?: string;
    mobile?: string;
    photo?: any;
    examination_date: Date;
    symptom?: string;
    location_name?: string;
    status: string;

}

export interface PatientState{
    patients: Patient[],
    loading: boolean
}


export const ADD_PATIENT="ADD_PATIENT";

export const LIST_PATIENT="LIST_PATIENT";

export const REMOVE_PATIENT="REMOVE_PATIENT";

export const UPDATE_PATIENT="UPDATE_PATIENT";

export const LOADING_PATIENT="LOADING_PATIENT";

interface AddPatientAction{
    type: typeof ADD_PATIENT;
    payload: Patient
}

interface RemovePatientAction{
    type: typeof REMOVE_PATIENT;
    payload: any;
}

interface UpdatePatientAction{
    type: typeof UPDATE_PATIENT;
    payload: Patient;
}

interface ListPatientAction{
    type: typeof LIST_PATIENT,
    payload: Patient[]
}

interface LoadingPatientAction{
    type: typeof LOADING_PATIENT,
    payload: boolean;
}

export type PatientActionTypes = AddPatientAction | 
RemovePatientAction | 
UpdatePatientAction |
ListPatientAction | LoadingPatientAction;