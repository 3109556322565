import { AGREE_ACCEPT, AGREE_UNACCEPT } from "./types"

export const agreeAcceptAction = () => {
    return {
        type: AGREE_ACCEPT
    }
}

export const agreeUnAcctptAction = () => {
    return {
        type: AGREE_UNACCEPT
    }
}
