import React, { ChangeEvent, ReactElement, useState,useContext } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { listPatientByHn } from '../store/patient/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import DialogComponent from '../components/DialogComponent';
import Grid from '@material-ui/core/Grid';
import { RouterContext } from '../hooks/useRouterProvider'
import { RouterProps } from 'react-router-dom';
interface Props {

}

const useStyles = makeStyles((theme: Theme) => (
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1)
        },
        button: {
            marginTop: theme.spacing(1)
        }
    })
))

export default function CheckRegister({ }: Props): ReactElement {
    const props = useContext<any>(RouterContext);
    const classes = useStyles()
    const [hn, setHn] = useState("");
    const [status, setStatus] = useState("");
    const dispatch = useDispatch();
    const { patients } = useSelector((state: any) => state.patientReducer);
    const [open, setOpen] = useState(false);
    const handleCheck = async () => {
        const currDate = new Date();
        const day = currDate.getDate().toString();
        const month = (currDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currDate.getFullYear().toString();
        const currDateString = `${year}-${month}-${day}`;
        await dispatch(listPatientByHn(currDateString, hn))
        setOpen(true);
    }
    const patient = patients[0] ? patients[0] : "";
    return (
        <Container maxWidth="xs">
            <Paper className={classes.paper}>
                <h1>ตรวจสอบยืนยันการเข้าตรวจ (Check in)</h1>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="hn"
                            placeholder="hn"
                            autoComplete="off"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setHn(e.target.value);
                            }}
                            value={hn}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button 
                        fullWidth
                        onClick={handleCheck} 
                        className={classes.button} 
                        variant="contained">ตรวจสอบ</Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button 
                        fullWidth
                        onClick={()=>{
                            props.history.goBack();
                        }} 
                        className={classes.button} 
                        variant="contained">ย้อนกลับ</Button>
                    </Grid>

                </Grid>
                <DialogComponent open={open}
                    setOpen={setOpen}
                    message={patient.status?"ดำเนินการลงทะเบียนเรียบร้อย":"คุณยังไม่ได้ลงทะเบียน"} />
            </Paper>
        </Container>
    )
}
