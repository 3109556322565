import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { UseFormMethods } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/AutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { LocationState } from '../../store/location/types';
import { listAction } from '../../store/location/actions';
import { ReducerType } from '../../store/types';
interface Props {
    formProp: UseFormMethods
}

const NotAppointmentFormComponent = ({ formProp }: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const { locations } = useSelector((state: ReducerType) => state.locationReducer)
    console.log(locations);
    useEffect(() => {
        dispatch(listAction());
    }, [])
    const { register, errors } = formProp;
    return (
        <React.Fragment>
            <Grid item xs={12} sm={12}>
                <TextField
                    autoComplete="off"
                    label="อาการ"
                    name="symptom"
                    variant="outlined"
                    fullWidth
                    inputRef={register({
                        required: "กรุณากรอกอาการด้วย"
                    })}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Autocomplete
                    freeSolo
                    options={locations.map((location) => location.description)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoComplete="off"
                            label="สถานที่ตรวจ"
                            name="location_name"
                            variant="outlined"
                            fullWidth
                            inputRef={register({
                                required: "กรุณากรอกสถานที่ตรวจด้วย"
                            })}
                        />
                    )}
                />


            </Grid>
        </React.Fragment>
    )
}

export default NotAppointmentFormComponent;