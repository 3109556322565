import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch } from 'react-redux';
import { listPatient, updatePatient } from '../store/patient/actions'
import { Patient } from '../store/patient/types';
import Button from '@material-ui/core/Button';
import CardComponent from './register-components/CardComponent';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    table: {
      minWidth: 650,
    },
    container: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1)
    }
  })
));

interface Props {

}

export default function RegisterList({ }: Props): ReactElement {
  const classes = useStyles();
  const { patients, loading } = useSelector((state: any) => state.patientReducer);
  const dispatch = useDispatch();
  const [dateSearch, setDateSearch] = useState<string>(new Date().toISOString().split('T')[0])

  const handleChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    setDateSearch(e.target.value);
  }
  const handleUpdate = (patient: Patient) => {
    const newPatient = { ...patient };
    if(newPatient.status==="รับเรื่อง"){
      newPatient.status = "ลงทะเบียน";
    }else{
      newPatient.status = "รับเรื่อง";
    }
    
    dispatch(updatePatient(newPatient))
  }
  useEffect(() => {
    dispatch(listPatient(dateSearch));
  }, [dateSearch])

  return (
    <>
      <Paper className={classes.container}>
        <Grid container spacing={1} >
          <Grid item xs={12}>
            <Typography variant="h4" align="center">จัดการลงทะเบียน</Typography>
          </Grid>
          <Grid item xs={12} >
            <TextField
              type="date"
              name="dateSearch"
              placeholder="วันที่ค้นหา"
              autoComplete="off"
              value={dateSearch}
              onChange={handleChangeDate}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>HN</TableCell>
                    <TableCell align="center">เลขบัตรประชาชน</TableCell>
                    <TableCell align="center">อีเมลล์</TableCell>
                    <TableCell align="center">เบอร์โทรศัพท์</TableCell>
                    <TableCell align="center">รูป</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {loading ? <div>loading</div> : patients.map((patient: Patient) => (
                    <TableRow key={patient.id}>
                      <TableCell component="th" scope="row">
                        {patient.hn}
                      </TableCell>
                      <TableCell align="left">{patient.rid}</TableCell>
                      <TableCell align="left">{patient.email}</TableCell>
                      <TableCell align="left">{patient.mobile}</TableCell>
                      <TableCell align="left"><img src={`http://202.28.95.5/registration/server_php/photos/${patient.photo}`}
                        width="100"
                        height="100" /></TableCell>
                      <TableCell align="left">{patient.status}</TableCell>
                      <TableCell align="left">
                      <Button onClick={() => handleUpdate(patient)} variant="contained">{patient.status==="รับเรื่อง"?"ลงทะเบียน":"รับเรื่อง"}</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* {loading ? <div>loading</div> : patients.map((patient: Patient) => (
            <Grid item xs={12} sm={4}>
              <CardComponent patient={patient} handleUpdate={handleUpdate} />
            </Grid>
          ))
          } */}

        </Grid>


      </Paper>
    </>
  )
}
