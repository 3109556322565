import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { loginUser } from '../store/users/actions';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { User } from '../store/users/types';
import { useHistory } from 'react-router-dom';
import { clientPath } from '../config/api';
interface Prop {

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1)
            },
            marginTop: theme.spacing(8)
        },
        head: {
            textAlign: "center"
        },
        paper: {
            padding: theme.spacing(1)
        },
        input: {
            marginBottom: theme.spacing(1)
        }
    })
);

const LoginFormComponent = ({ }: Prop): React.ReactElement => {
    const history = useHistory()
    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const onSubmit = async (value: User) => {
        setLoading(true);
        await dispatch(loginUser(value));
        setLoading(false);
        history.push(`${clientPath}/new-register-list`);
    }
    return (
        <Container maxWidth="xs" className={classes.root}>
            <Paper className={classes.paper}>
                <h2 className={classes.head}>Login Form</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField name="username"
                        inputRef={register({
                            required: "Username is required"
                        })}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="username"
                        fullWidth
                        label="username"
                        error={errors.username}
                        helperText={errors.username && errors.username.message}
                        className={classes.input}
                    />
                    <TextField name="password"
                        inputRef={register({
                            required: "Password is required"
                        })}
                        type="password"
                        fullWidth
                        variant="outlined"
                        label="password"
                        autoComplete="off"
                        error={errors.password}
                        helperText={errors.password && errors.password.message}
                        className={classes.input}
                    />
                    
                    <Button type="submit"
                        style={{
                            transform: loading?"scale(1.1)":"",
                            transition: "transform 0.3s",
                            cursor: loading?"not-allowed":"pointer",
                            
                        }}
                        variant="contained">{loading ? ("Logging...") : ("Login")}</Button>
                </form>
            </Paper>
        </Container>
    );
}

export default LoginFormComponent;