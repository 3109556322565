import React, { ChangeEvent, ReactElement, useEffect, useState, useReducer } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch } from 'react-redux';
import { clearMany, listNewPatientAction, listOneNewPatientAction, sendNewPatientToHoAction, setDataSearch, updateNewPatientAction } from '../store/new-patient/actions'
import { NewPatient, NewPatientState } from '../store/new-patient/types';
import { Button, Grid, Theme, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { clientPath, host } from '../config/api';
import LoadingComponent from './LoadingComponent';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    table: {
      minWidth: 650,
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1)
    },
    textSearch: {
      marginBottom: theme.spacing(1)
    },
    head: {
      marginBottom: theme.spacing(1),
      textAlign: "center"
    }
  })
))

interface Props {

}

const NewRegisterListComponent = ({ }: Props): React.ReactElement => {

  const classes = useStyles();
  const newPatientReducer = useSelector((state: any) => state.newPatientReducer);
  const dataSearch = newPatientReducer.dataSearch;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingUpdateHo, setLoadingUpdateHo] = useState(false);
  const [loadingIdUpdateHo, setLoadingIdUpdateHo] = useState("");
  //const [dateSearch,setDateSearch] = useState<string>(new Date().toISOString().split('T')[0])
  const [observeredEl,setObserveredEl] = useState<HTMLParagraphElement | null>(null);
  const handleChangeData = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setDataSearch({
      ...dataSearch,
      [e.target.name]: e.target.value
    }))
  }

  const handleSendToHO = async (id: string) => {
    setLoadingUpdateHo(true);
    setLoadingIdUpdateHo(id);
    const listPatient = await dispatch<any>(listOneNewPatientAction(id));

    if (listPatient.payload) {
      const ho: any = await dispatch(sendNewPatientToHoAction(id))
      
      if (ho.data[0].MessageCode === "E01") {
        alert("มีข้อมูลผู้ป่วยแล้ว");
      } 
      // else if (ho.data[0][""].substring(0, 10) === "[ALPHASEQ]") {
      //   alert("บันทึกสำเร็จ");
      //   listPatient.payload.send_to_ho = "1";
      //   const updateNewPatient = dispatch(updateNewPatientAction(listPatient.payload));
      // }
      else if (ho.data[0].MessageCode === "001") {
        alert("บันทึกสำเร็จ");
        listPatient.payload.send_to_ho = "1";
        listPatient.payload.hn = ho.data[0].HN;
        const updateNewPatient = dispatch(updateNewPatientAction(listPatient.payload));
      } else {
        alert("ไม่สามารถเชื่อมต่อระบบได้");
      }
    }
    setLoadingUpdateHo(false);
  }
  
  let countPage = 1;
  const getData =  async () => {
    setLoading(true);
    dispatch(clearMany());  
    await dispatch(listNewPatientAction(dataSearch,countPage));
    setLoading(false);
  }
  useEffect(() => {
    getData();
  }, [])
  
  const observer = new IntersectionObserver((items)=>{
    (
      async () => {
        if (items[0].isIntersecting) {
          // if more data, load more
          countPage++;
          await dispatch(listNewPatientAction(dataSearch,countPage));
        }
        //setLoading(true);
        //await dispatch(listNewPatientAction(dataSearch,2));
        //setLoading(false);
      }
    )()
  },{threshold: 1})

  useEffect(()=>{
    if(observeredEl){
      observer.observe(observeredEl);
    }
    return ()=>{
      if(observeredEl){
        observer.unobserve(observeredEl);
      }
    }
    
  },[observeredEl])

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.head} variant="h5" component="h6">รายการลงทะเบียนใหม่</Typography>
      {/*<TextField
        type="date"
        name="dateSearch"
        label="วันที่ค้นหา"
        autoComplete="off"
        value={dateSearch}
        onChange={handleChangeDate}
        variant="outlined"
        className={classes.textSearch}
      /> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <TextField
            name="gen_code"
            autoComplete="off"
            label="QR Code"
            variant="outlined"
            onChange={handleChangeData}
            value={dataSearch.gen_code}
            onKeyPress={
              (e)=>{
                if(e.key==="Enter"){
                  getData();
                }
              }
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            name="rid"
            autoComplete="off"
            label="เลขบัตรประชาชน"
            variant="outlined"
            onChange={handleChangeData}
            value={dataSearch.rid}
            onKeyPress={(e: React.KeyboardEvent)=>{
              if(e.key === "Enter"){
                getData();
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            name="fname"
            autoComplete="off"
            label="ชื่อ"
            variant="outlined"
            fullWidth
            onChange={handleChangeData}
            value={dataSearch.fname}
            onKeyPress={(e)=>{
              if(e.key === "Enter"){
                getData();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            name="lname"
            autoComplete="off"
            label="นามสกุล"
            variant="outlined"
            onChange={handleChangeData}
            value={dataSearch.lname}
            onKeyPress={(e)=>{
              if(e.key === "Enter"){
                getData();
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1}><Button style={{height: "55px"}} type="button" onClick={getData} variant="outlined">ค้นหา</Button></Grid>
        
      </Grid>


      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">id</TableCell>
              <TableCell align="center">เลขบัตรประชาชน</TableCell>
              <TableCell align="center">ชื่อ</TableCell>
              <TableCell align="center">นามสกุล</TableCell>
              <TableCell align="center">วันเกิด</TableCell>
              <TableCell align="center">เบอร์โทรศัพท์มือถือ</TableCell>
              <TableCell align="center">SMS</TableCell>
              <TableCell align="center">QR Code</TableCell>
              <TableCell align="center">Picture</TableCell>
              <TableCell align="center">Picture</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          {!loading ? (
            <TableBody>
              {newPatientReducer.newPatients.map((newPatient: NewPatient) => (
                <TableRow key={newPatient.id}>
                  {/* <TableCell component="th" scope="row">
                    {newPatient.rid}
                  </TableCell> */}
                  <TableCell align="left">{newPatient.id}</TableCell>
                  <TableCell align="left">{newPatient.rid ? newPatient.rid : newPatient.passport}</TableCell>
                  <TableCell align="left">{newPatient.fname}</TableCell>
                  <TableCell align="left">{newPatient.lname}</TableCell>
                  <TableCell align="left">{`${newPatient.birth_day}/${newPatient.birth_month}/${newPatient.birth_year}`}</TableCell>
                  <TableCell align="left">{newPatient.mobile}</TableCell>
                  <TableCell align="left">{newPatient.active}</TableCell>
                  <TableCell align="left">{newPatient.gen_code}</TableCell>
                  <TableCell align="left"><img style={{ height: 200, width: 200 }} src={`${host}/server_php/new-photos/${newPatient.photo}`} /></TableCell>
                  <TableCell align="left"><img style={{ height: 200, width: 200 }} src={`${host}/server_php/new-photos2/${newPatient.photo2}`} /></TableCell>
                  <TableCell align="left"><NavLink

                    to={`${clientPath}/new-register-list/${newPatient.id}`}>แก้ไข</NavLink></TableCell>
                  <TableCell align="left">
                    {
                      newPatient.send_to_ho === "1"
                      ?newPatient.hn
                      :<Button type="button" onClick={() => {
                        if (newPatient.id) {
                          handleSendToHO(newPatient.id);
                        }
                      }} variant="contained"
                        style={{
                          transform: loadingUpdateHo && (newPatient.id === loadingIdUpdateHo) ? "scale(1.1)" : "",
                          transition: "transform 0.3s",
                          cursor: loadingUpdateHo ? "not-allowed" : "pointer",
                        }}
                        disabled={newPatient.send_to_ho === "1" ? true : false}
                      >{loadingUpdateHo && (newPatient.id === loadingIdUpdateHo) ? "Loading..." : "TOHO"}</Button>
                    }
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (<LoadingComponent />)}

        </Table>
      </TableContainer>
      {
        
        (newPatientReducer.newPatients && newPatientReducer.newPatients.length) < 
        (newPatientReducer.count)
        && (<p ref={setObserveredEl}>load more...</p>)
      }
    </Paper>
  )
}

export default NewRegisterListComponent;