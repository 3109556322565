import { IconButton } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

interface Props {
    formProp: UseFormMethods
}

const AppointmentFromComponent = ({ formProp }: Props): ReactElement => {
    const { register, errors } = formProp;
    return (
        <React.Fragment>
            <Grid item xs={12} sm={12}>
                <input accept="image/*" name="photo" ref={register({
                    required: "กรุณาระบุภาพถ่าย"
                })} style={{ display: "none" }} id="icon-button-file" type="file" />
                <label htmlFor="icon-button-file">
                    <span>ภาพถ่ายบัตรนัด</span>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                    </IconButton>
                    {errors.photo && <div style={{ paddingTop: "1px", color: "red" }}>{errors.photo.message}</div>}
                </label>
            </Grid>
        </React.Fragment>
    );
}

export default AppointmentFromComponent;