export interface Regional{
    name_tha?: string;
}

export interface RegionalStateType{
    regionals: Regional[],
    regional: Regional
}

export const REGIONAL_LIST_ACTION = "REGIONAL_LIST_ACTION";

interface RegionalListAction{
    type: typeof REGIONAL_LIST_ACTION;
    payload: Regional[];
}
export type RegionalActionType = RegionalListAction;