import { FormControl,FormControlLabel,FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import React,{useState} from 'react';
import { UseFormMethods } from 'react-hook-form';




interface Props {
    right_treatment: string;
    right_treatment_desc: string;
    useFormMethods: UseFormMethods; 
}

interface RenderTreatmentDescProp{

}

const RightTreatmentFormComponent = ({ right_treatment,right_treatment_desc,useFormMethods }: Props): React.ReactElement=>{
    const [rightTreatment, setRightTreatment] = useState(right_treatment);
    const { register,errors } = useFormMethods;

    const RenderTreatmentDesc = ({  }:RenderTreatmentDescProp): React.ReactElement => {
        
        return (
            <TextField
                name="right_treatment_desc"
                variant="outlined"
                autoComplete="off"
                label={rightTreatment === "อื่นๆ" ? "อื่นๆ" : "โรงพยาบาล"}
                fullWidth
                inputRef={register}
                defaultValue={right_treatment_desc}
            />
        )
    }
    
    return (
        <Grid item xs={12} sm={12}>
            <FormControl fullWidth component="fieldset">
                <FormLabel component="legend">
                    สิทธิการรักษาพยาบาล
                    
                </FormLabel>
                
                <RadioGroup onChange={(e: any) => {
                    setRightTreatment(e.target.value);
                }} aria-label="right_treatment" defaultValue={right_treatment} name="right_treatment" >
                    <FormControlLabel value="ชำระเอง" inputRef={register({
                        required: "กรุณากรอกสิทธิรักษาพยาบาลด้วย"
                    })} control={<Radio />} label="ชำระเอง" />
                    <FormControlLabel value="ข้าราชการ" inputRef={register} control={<Radio />} label="ข้าราชการ" />
                    <FormControlLabel value="รัฐวิสาหกิจ" inputRef={register} control={<Radio />} label="รัฐวิสาหกิจ" />
                    <div style={{ display: 'inline-flex' }}>
                        <FormControlLabel value="ประกันสุขภาพถ้วนหน้า" inputRef={register} control={<Radio />} label="ประกันสุขภาพถ้วนหน้า" />
                        {rightTreatment === "ประกันสุขภาพถ้วนหน้า" ? <div style={{ width: "70%" }}><RenderTreatmentDesc /></div> : null}
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        <FormControlLabel value="ประกันสังคม" inputRef={register} control={<Radio />} label="ประกันสังคม" />
                        {rightTreatment === "ประกันสังคม" ? <div style={{ width: "70%" }}><RenderTreatmentDesc /></div> : null}
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        <FormControlLabel value="อื่นๆ" inputRef={register} control={<Radio />} label="อื่นๆ" />
                        {rightTreatment === "อื่นๆ" ? <div style={{ width: "70%" }}><RenderTreatmentDesc /></div> : null}
                    </div>

                </RadioGroup>
                {
                    errors.right_treatment && <p style={{color: "red"}}>{errors.right_treatment.message}</p>
                }
            </FormControl>
        </Grid>
    )
    
}

export default RightTreatmentFormComponent;