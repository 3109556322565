import axios from 'axios';
import { Action } from 'redux';
import { Nation, NATION_LIST_ACTION } from './types';
import { apiPath } from '../../config/api'
const list = (payload: Nation[])=>{
    return{
        type: NATION_LIST_ACTION,
        payload
    }
}

export const nationListAction = () => {
    return async (dispatch: (arg: Action)=>void)=>{
        const nations = await axios.get(`${apiPath}/nations/`);
        dispatch(list(nations.data));
    }
}