export interface Prename{
    id: number | string;
    name: string
}

export interface PrenameState{
    prenames: Prename[];
    prename: Prename;
}

export const LIST_PRENAME = "LIST_PRENAME";

interface ListType{
    type: typeof LIST_PRENAME;
    payload: Prename[]
}

export type PrenameActionType = ListType;

