import React from 'react';
import Container from '@material-ui/core/Container';
import RegisterForm from '../components/RegisterFormComponent';

interface Props{

}
const Register:React.FC = ({}:Props)=>{
    return(
        <Container maxWidth="sm" component="main">
            <RegisterForm />
        </Container>
    );
}

export default Register;