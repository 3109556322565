
import { Marry,MarryActionType, MarryStateType, MARRY_LIST_ACTION } from "./types";
const initState = {
    marry: {},
    marrys: []
}
const reducer = (state: MarryStateType=initState,action: MarryActionType) => {
    switch(action.type){
        case MARRY_LIST_ACTION:
            return {
                ...state,
                marrys: action.payload
            }
        default: 
            return state;
    }
}
export default reducer;