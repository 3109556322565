import { PatientActionTypes,Patient,ADD_PATIENT, LIST_PATIENT, UPDATE_PATIENT, LOADING_PATIENT } from './types';
import {apiPath} from '../../config/api';
import axios from 'axios';

const addPatientToReducer = (payload:Patient):PatientActionTypes=>{
        return{
            type: ADD_PATIENT,
            payload
        }
}

const listPatientToReducer = (payload: Patient[]):PatientActionTypes=>{
    return{
        type: LIST_PATIENT,
        payload
    }
}

const updatePatientToReducer = (payload:Patient):PatientActionTypes=>{
    return{
        type: UPDATE_PATIENT,
        payload
    }
}

const setLoadingToReducer = (payload:boolean):PatientActionTypes=>{
    return {
        type: LOADING_PATIENT,
        payload
    }
}

export const addPatient = (payload:Patient)=>{
    return async (dispatch:any)=>{
        if(payload.photo){
            const data = new FormData();
            data.append('photo',payload.photo[0]);
            const photo = await axios.post(`${apiPath}/patient-photo/`,data);
            
            payload.photo = photo.data;  
        }
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const status = await axios.post(`${apiPath}/patient/`,payload,options).then(result=>{
            dispatch(addPatientToReducer(payload));
            return result;
        });
        
        return status;
    }
}

export const listPatient = (dateSearch: string)=>{
    return async (dispatch: any)=>{
        //const url = `${apiPath}/patient/${dateSearch}`;
        const url = `${apiPath}/patient/?dateSearch=${dateSearch}`;
        setLoadingToReducer(true);
        const result = await axios.get(url)
        dispatch(listPatientToReducer(result.data));
        //setLoadingToReducer(false);   
    }
}

export const listPatientByHn = (dateSearch: string,hn: string)=>{
    return async (dispatch: any)=>{

        const result = await axios.get(`${apiPath}/patient/?dateSearch=${dateSearch}&hn=${hn}`);
        dispatch(listPatientToReducer(result.data));
        
    }
}


export const updatePatient = (payload:Patient)=>{
    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return (dispatch: any)=>{
        axios.post(`${apiPath}/patient/?id=${payload.id}`,payload,options).then(result=>{
            dispatch(updatePatientToReducer(payload));
        })
    }
}


export const loadingPatient = (payload:boolean)=>{
    return (dispatch:any)=>{
        dispatch(setLoadingToReducer(payload));
    }
}