import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { clientPath } from '../config/api';
import { RouterContext } from '../hooks/useRouterProvider';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../store/users/types';
import { ReducerType } from '../store/types';
import { logoutUser } from '../store/users/actions';
import { red } from '@material-ui/core/colors';
import logo1 from '../images/logo1.png'
import logo2 from '../images/logo2.jpg'
import { IconButton } from '@material-ui/core';

interface Props {

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    link: {
      marginRight: theme.spacing(3),
      color: "white",
      textDecoration: "none",
      fontSize: 16,
    },
    linkActive: {
      color: red['A200']
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.primary.main
    },
    toolbarTitle: {
      display: "flex",
      flexGrow: 1,
      color: "white",
      alignItems: "center",
    },
  })
);

const Nav: React.FC = ({ }: Props) => {
  const classes = useStyles();
  const { history } = useContext<any>(RouterContext);
  const dispatch = useDispatch();

  const user: User = useSelector((selector: ReducerType) => {
    return selector.userReducer;
  });
  // const handleLogin = () => {
  //   history.push(`${clientPath}/login`);
  // }
  const handleLogout = () => {
    dispatch(logoutUser());
  }

  return (
    <React.Fragment>
      <header className="w-full py-4 px-12 bg-green-900">
        <div className="font-semibold text-2xl flex items-center justify-center">
          <img src={logo1} alt="logo" className="w-28 h-28 mr-2" />
          <img src={logo2} alt="logo2" className="w-28 h-28 ml-2" />
        </div>
        <div style={{ textAlign: "center", marginTop: "10px", fontSize: "22px", color: "white" }}>
          <p>คณะแพทยศาสตร์ มหาวิทยาลัยขอนแก่น</p>
        </div>
      </header>
      <nav className="px-20 py-2 bg-green-700">
        <div className="flex w-full justify-center items-center">
          
          {/* <NavLink exact
            activeClassName="bg-blue-500"
            className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/`}>
            <i className="fas fa-home"></i> หน้าหลัก</NavLink>
          
          <NavLink 
            activeClassName="bg-blue-500"
            className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/admin-manual`}>
            <i className="fas fa-home"></i> คู่มือการใช้งาน</NavLink>

          <NavLink
            activeClassName="bg-blue-500"
            className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/new-register`}>
            <i className="far fa-registered"></i> ทำบัตรใหม่</NavLink>

          {user.email ? (
            <>
              <NavLink activeClassName="bg-blue-500" className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/new-register-list`}>
                <i className="fas fa-check-circle"></i> จัดการบัตรใหม่
              </NavLink>
              
            </>
          ) : null
          }

          {
            user.email ?
              <div className="flex items-center ml-4 text-green-100">
              <div>ยินดีต้อนรับ {user.fullnames}</div>
              <IconButton onClick={handleLogout}>
                <span className="text-white"><i className="fas fa-sign-out-alt"></i></span>
              </IconButton>
              </div>
               :
              (
                <NavLink activeClassName="bg-blue-500"
                  to={`${clientPath}/login`}
                  className="text-white text-base p-2 hover:-mt-2">
                  <i className="fas fa-sign-in-alt"></i> เข้าสู่ระบบ
                </NavLink>
              )

          } */}
          {/* 
          <NavLink
            activeClassName="bg-blue-500"
            className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/check-new-register`}>
            <i className="far fa-check-circle"></i> ตรวจสอบการทำบัตรใหม่</NavLink>
          <NavLink activeClassName="bg-blue-500" className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/manage-register`}>
                <i className="fas fa-check-circle"></i> จัดการ Check in
              </NavLink>
          <NavLink
            activeClassName="bg-blue-500"
            className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/register`}>
            <i className="fas fa-registered"></i> ยืนยันการเข้าตรวจ (Check in)</NavLink> */}
          {/* <NavLink
            activeClassName="bg-blue-500"
            className="text-white text-base p-2 hover:-mt-2" to={`${clientPath}/check-register`}>
            <i className="fas fa-check-circle"></i> ตรวจสอบยืนยันการเข้าตรวจ (Check in)</NavLink> */}

        </div>
      </nav>

    </React.Fragment>
  )
}

export default Nav;