import React from 'react';
import { Route,Switch } from 'react-router-dom';
import Home from './pages/Home';
import Nav from './pages/Nav';
import Container from '@material-ui/core/Container'
import Register from './pages/Register';
import CheckRegister from './pages/CheckRegister';
import ManageRegister from './pages/ManageRegister';
import {clientPath} from './config/api';
import NewRegister from './pages/NewRegister';
import NewRegisterList from './pages/NewRegisterList';
import { useSelector } from 'react-redux';
import Login from './pages/Login';
import { User } from './store/users/types';
import { ReducerType } from './store/types';
import Footer from './pages/Footer';
import { Box } from '@material-ui/core';
import CheckNewRegister from './pages/CheckNewRegister';
import NewRegisterProfile from './pages/NewRegisterProfile';
import Condition from './pages/Condition';
import QrCode from './pages/QrCode';
import UserManual from './pages/UserManual';
import AdminManual from './pages/AdminManual';
const Routes:React.FC = ()=>{
    const user: User = useSelector((selector: ReducerType)=>{
        return selector.userReducer;
    })
    return (
        <>
        <Nav />
        <div className="bg-green-100 py-6">
        <Switch>
            
            <Route path={`${clientPath}/register`} component={Register} />
            <Route path={`${clientPath}/user-manual`} component={UserManual} />
            <Route path={`${clientPath}/admin-manual`} component={AdminManual} />
            <Route path={`${clientPath}/check-register`} component={CheckRegister} />
            <Route path={`${clientPath}/new-register`} component={NewRegister} />
            <Route path={`${clientPath}/check-new-register`} component={CheckNewRegister} />
            <Route path={`${clientPath}/condition`} component={Condition} />
            <Route path={`${clientPath}/new-register-profile/:id`} component={NewRegisterProfile} />
            <Route path={`${clientPath}/qr-code/:id`} component={QrCode} />
            {
                user.email?(
                    <>
                    <Route path={`${clientPath}/manage-register`} component={ManageRegister} />
                    <Route path={`${clientPath}/new-register-list/:id`} component={NewRegisterList} />
                    <Route path={`${clientPath}/new-register-list`} component={NewRegisterList} />
                    </>
                ):<Route path={`${clientPath}/login`} component={Login} />
            }
            
            <Route path={`${clientPath}/`} component={Home} />
        </Switch>
        </div>
        <div className="bg-green-800 p-10">
        <Footer />
        </div>
        </>
    )
}

export default Routes;