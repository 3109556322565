import { useEffect,useState } from "react";
import axios from 'axios';
import { apiPath } from "../config/api";
import { listAmper } from '../store/amper/actions';
import { useDispatch } from 'react-redux';
import { listTambolAction } from "../store/tambol/actions";
type DateType = {
    id: string;
    name: string;
}

export const useNewRegister = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        getYear();
        getMonth();
        getDay();
    },[])
    const [year,setYear] = useState<DateType[]>([]);
    const [month,setMonth] = useState<DateType[]>([])
    const [day,setDay] = useState<DateType[]>([])
    const [provinceCode,setProvinceCode] = useState("");
    const [loadingAmper,setLoadingAmper] = useState(false);
    const dataMonth: DateType[] = [
        {
            id: "01",
            name: "มกราคม"
        },
        {
            id: "02",
            name: "กุมภาพันธ์"
        },
        {
            id: "03",
            name: "มีนาคม"
        },
        {
            id: "04",
            name: "เมษายน"
        },
        {
            id: "05",
            name: "พฤษภาคม"
        },
        {
            id: "06",
            name: "มิถุนายน"
        },
        {
            id: "07",
            name: "กรกฎาคม"
        },
        {
            id: "08",
            name: "สิงหาคม"
        },
        {
            id: "09",
            name: "กันยายน"
        },
        {
            id: "10",
            name: "ตุลาคม"
        },
        {
            id: "11",
            name: "พฤศจิกายน"
        },
        {
            id: "12",
            name: "ธันวาคม"
        },
    ]
    const getYear = () => {
        const d = new Date();
        
        let dataYear: DateType[] = [];
        for(let i=0;i<120;i++){
            const tempYear = (d.getFullYear()+543-i).toString();
            dataYear.push(
                {
                    id: tempYear,
                    name: tempYear,
                }
            )
        }
        setYear(dataYear);
    }

    const getMonth = () => {
        setMonth(dataMonth);   
    }
    const getDay = () => {
        let dataDay: DateType[] = [];
        for(let i=1;i<32;i++){
            dataDay.push({
                id:i.toString().padStart(2,'0'),
                name:i.toString().padStart(2,'0'),
            })
        }
        setDay(dataDay);
    }
    const getMonthNumber = (name: string) => {
        const dataMonth = month.filter((result=>result.name===name));
        return dataMonth[0].id;
    }

    const getMonthName = (id: string) =>{
        const monthData = dataMonth.filter((result=>result.id===id));
        return monthData[0].name;
    }

    const getProvinceCode = async (name: string) => {
        setLoadingAmper(true);
        const province = await axios.get(`${apiPath}/province/?name=${name}`);
        dispatch(listAmper(province.data[0].code));
        setLoadingAmper(false);
    }

    const getAmperCode = async (name: string) => {
        const amper = await axios.get(`${apiPath}/amper/?name=${name}`);
        if(amper.data.length > 0){
            dispatch(listTambolAction(amper.data[0].code));
        }
    }

    const checkRid = async (rid: string) => {
        const newPatient = await axios.get(`${apiPath}/new-patient/?rid=${rid}`);
        
        if(newPatient.data.length > 0){
            return true;
        }
        return false;
    }

    return { year,month,day,provinceCode,getMonthNumber,getMonthName,getProvinceCode,getAmperCode,loadingAmper,checkRid }
}