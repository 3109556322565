import { CareerPrimaryActionType, CareerPrimaryState,CAREERPRIMARYLIST } from "./types";

const initState = {
    careerPrimarys: []
}

const reducer = (state: CareerPrimaryState=initState,action: CareerPrimaryActionType)=>{
    switch(action.type){
        case CAREERPRIMARYLIST:
            
            return {
                ...state,
                careerPrimarys: action.payload
            }
        default:
            return state;
    }
}

export default reducer;