import axios from 'axios';
import { CareerSecondary, CAREERSECONDARYLIST } from './types';
import { apiPath } from '../../config/api'
const list = (payload: CareerSecondary[])=>{
    return {
        type: CAREERSECONDARYLIST,
        payload
    }
}

export const careerSecondaryList=()=>{
    return async(dispatch: any)=>{
        const payload = await axios.get<CareerSecondary[]>(`${apiPath}/career-secondary/`);
        dispatch(list(payload.data))
    }
}
