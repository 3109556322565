import React, { ChangeEvent, useRef, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { Button, Dialog, FormControl, FormControlLabel, FormLabel, IconButton, MenuItem, Paper, Radio, RadioGroup } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { add, addNewPatientAction, clearOne, listOneNewPatientAction, updateNewPatientAction } from '../store/new-patient/actions';
import { NewPatient } from '../store/new-patient/types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import AutoComplete from '@material-ui/lab/AutoComplete';
import { listPrename } from '../store/prename/actions';
import { Prename, PrenameState } from '../store/prename/types';
import { ReducerType } from '../store/types';
import Checkbox from '@material-ui/core/Checkbox';
import { Patient } from '../store/patient/types';
import { History } from 'history';
import { match } from 'react-router-dom';
import { listProvince } from '../store/province/actions';
import { PhotoCamera, SelectAllRounded } from '@material-ui/icons';
import { listAmper } from '../store/amper/actions';
import { listTambolAction } from '../store/tambol/actions';
import DialogComponent from './DialogComponent';
import { clientPath } from '../config/api';
import RightTreatmentFormComponent from './register-components/RightTreatmentFormComponent';
import { listCareerPrimaryAction } from '../store/career-primary/actions';
import { careerSecondaryList } from '../store/career-secondary/actions';
import Autocomplete from '@material-ui/lab/AutoComplete';
import MaskInput from 'react-text-mask';
import TextMaskCustom from './TextMaskCustom';
import { listAction } from '../store/location/actions';
import { bloodListAction } from '../store/blood/actions';
import { listEducationAction } from '../store/education/actions';
import { regionalListAction } from '../store/regionals/actions';
import { nationListAction } from '../store/nations/actions';
import { raceListAction } from '../store/races/actions';
import { marryListAction } from '../store/marry/actions';
import OtpComponent from './new-register-components/OtpComponent';
import { useNewRegister } from '../hooks/useNewRegister';
import AlertDupRid from './new-register-components/AlertDupRid';
interface Params {
    id: string;
}
interface Props {
    history: History,
    match: match<Params>
}

const useStyles = makeStyles((theme: Theme) => (
    createStyles({
        root: {
            '& > *': {
                marginTop: theme.spacing(1)
            }
        },
        paper: {
            //marginTop: theme.spacing(2),
            padding: theme.spacing(2)
        },
    })
))

const statusesData = [
    'โสด', 'คู่', 'หม้าย', 'แยกกันอยู่', 'หย่า', 'บวช'
]

const bloodTypeData = [
    "A", "B", "AB", "O", "ไม่ทราบ", "ไม่เคยตรวจเลือด"
]
const drugAllergyData = [
    "ไม่ทราบ", "ไม่มีประวัติการแพ้"
]

const NewRegisterFormComponent = ({ match, history }: Props): React.ReactElement => {
    
    const { year, month, day, provinceCode, getMonthNumber, getAmperCode,
        getProvinceCode, loadingAmper, getMonthName,checkRid } = useNewRegister();
    const { newPatient } = useSelector((selector: ReducerType) => selector.newPatientReducer);

    const { provinces } = useSelector((selector: ReducerType) => selector.provinceReducer);

    const { ampers } = useSelector((selector: ReducerType) => selector.amperReducer);
    const { tambols } = useSelector((selector: ReducerType) => selector.tambolReducer);

    const { careerPrimarys } = useSelector((selector: ReducerType) => selector.careerPrimaryReducer);
    const frm = useForm<any>();
    const { register, handleSubmit, errors, setError, setValue } = frm;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openRidDup,setOpenRidDup] = useState(false);
    const [isNonThai, setIsNonThai] = useState(newPatient.isNonThai);
    const dispatch = useDispatch();
    const { educations } = useSelector((selector: ReducerType) => selector.educationReducer)
    const onSubmit = async (value: NewPatient) => {
        setLoading(true);
        if (value.rid) {
            value.rid = value.rid.replaceAll(' ', '');
        } else {
            value.rid = "";
        }
        
        const isRid = await checkRid(value.rid);
        if(isRid){
            setLoading(false);
            setOpenRidDup(true);
            return false;
        }

        value.birth_month = getMonthNumber(value.birth_month);
        if (newPatient.id) {
            value.id = newPatient.id;
            if (value.photo.length == 0) {
                value.photo = newPatient.photo;
            }
            if (value.photo2.length == 0) {
                value.photo2 = newPatient.photo2;
            }
            await dispatch(updateNewPatientAction(value));
            setLoading(false);

        } else {
            if(value.moo){
                //value.moo = value.moo.replace('-','');
                value.moo = "ม."+ value.moo;
            }
            setLoading(true)
            await dispatch(addNewPatientAction(value));
            setOpen(true);
        }
        setLoading(false);
    }

    const { prenames } = useSelector((selector: ReducerType) => selector.prenameReducer);
    const { careerSecondarys } = useSelector((selector: ReducerType) => selector.careerSecondaryReducer)
    const { locations } = useSelector((selector: ReducerType) => selector.locationReducer);
    useEffect(() => {
        getProvinceCode(newPatient.province);
        getAmperCode(newPatient.amper);
        (async () => (await dispatch(listPrename())))();
        (async () => (await dispatch(listProvince())))();

        //(async () => await dispatch(listAmper(provinceCode)))();
        //(async () => await dispatch(listTambolAction("")))();
        (async () => await dispatch(listCareerPrimaryAction()))();
        (async () => await dispatch(careerSecondaryList()))();
        (async () => await dispatch(listAction()))();
        dispatch(marryListAction());
        dispatch(bloodListAction());
        dispatch(listEducationAction());
        dispatch(regionalListAction());
        dispatch(nationListAction());
        dispatch(raceListAction());
        
        return () => {
            dispatch(clearOne());
        }
    }, []);

    const classes = useStyles();
    const { bloods } = useSelector((state: ReducerType) => (state.bloodReducer));
    const { marrys } = useSelector((state: ReducerType) => (state.marryReducer));
    const { regionals } = useSelector((selector: ReducerType) => (selector.regionalReducer));
    const { nations } = useSelector((selector: ReducerType) => selector.nationReducer);
    const { races } = useSelector((selector: ReducerType) => selector.raceReducer);
    let birth_month = "";
    if (newPatient.birth_month) {
        birth_month = getMonthName(newPatient.birth_month);
    }
    return (

        <Container>
            <Paper>
                <div className="p-4 text-2xl text-center bg-green-600 text-white">
                    แบบกรอกข้อมูลเพื่อทำบัตรตรวจโรคโรงพยาบาลศรีนครินทร์
                </div>
            </Paper>
            <Paper className={classes.paper}>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Typography variant="h5" component="h1" gutterBottom align="center"
                        className="bg-green-600 text-white p-2">ข้อมูลส่วนบุคคล</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                autoFocus
                                name="rid"
                                autoComplete="off"
                                id="rid"
                                inputRef={register({
                                    required: isNonThai ? false : "กรุณากรอกบัตรประชาชนด้วย",
                                    pattern: {
                                        value: /^[0-9]+[ ]+[0-9]+[0-9]+[0-9]+[0-9]+[ ]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[ ]+[0-9]+[0-9]+[ ]+[0-9]$/i,
                                        message: "กรุณากรอกเลขบัตรประชาขนให้ถูกต้อง"
                                    },
                                    validate: async (value: string) => {
                                        //return true;
                                        if (isNonThai) {
                                            return true;
                                        }
                                        let total = 0;
                                        let iPID;
                                        let chk;
                                        let Validchk;
                                        iPID = value.replace(/ /g, "");
                                        Validchk = iPID.substr(12, 1);
                                        let j = 0;
                                        let pidcut;
                                        for (let n = 0; n < 12; n++) {
                                            pidcut = parseInt(iPID.substr(j, 1));
                                            total = (total + ((pidcut) * (13 - n)));
                                            j++;
                                        }

                                        chk = 11 - (total % 11);

                                        if (chk == 10) {
                                            chk = 0;
                                        } else if (chk == 11) {
                                            chk = 1;
                                        }
                                        if (chk == Number.parseInt(Validchk)) {
                                            //const isRid = await checkRid(iPID);
                                            //if(!isRid){
                                                return true;
                                            //}else{
                                            //    return "ท่านได้ทำการบันทึกข้อมูลในระบบลงทะเบียนผู้ป่วยใหม่ออนไลน์แล้ว";    
                                            //}
                                        } else {
                                            return "กรุณากรอกเลขบัตรประชาขนให้ถูกต้อง";
                                        }

                                    }
                                })}
                                variant="outlined"
                                fullWidth
                                InputProps={
                                    {
                                        inputComponent: TextMaskCustom as any,
                                        inputProps: { masks: [/\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/] }
                                    }
                                }
                                label="เลขบัตรประชาชน *"
                                defaultValue={newPatient.rid}
                                helperText={errors.rid && errors.rid.message}
                                error={errors.rid}
                                disabled={isNonThai}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div style={{ display: "inline-flex", width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isNonThai}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                setIsNonThai(e.target.checked);
                                                setValue("rid", "");
                                                setValue("passport", "");
                                            }}
                                            name="isNonThai"
                                            color="primary"
                                            inputRef={register}
                                        />
                                    }
                                    label="กรณีเป็นชาวต่างชาติ"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {
                                isNonThai && (
                                    <TextField
                                        id="passport"
                                        name="passport"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอก passport"
                                        })}
                                        variant="outlined"
                                        label="เลขหนังสือเดินทาง"
                                        defaultValue={newPatient.passport}
                                        autoComplete="off"
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                id="prename"
                                options={
                                    prenames.map((prename) => {
                                        return prename.name
                                    })
                                }
                                defaultValue={newPatient.prename}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="prename"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกคำนำหน้าชื่อด้วย"
                                        })}
                                        variant="outlined"
                                        label="คำนำหน้า *"
                                        helperText={errors.prename && errors.prename.message}
                                        error={errors.prename}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="fname"
                                autoComplete="off"
                                id="fname"
                                inputRef={register({
                                    required: "กรุณากรอกชื่อด้วย"
                                })}
                                label="ชื่อ *"
                                variant="outlined"
                                fullWidth
                                error={errors.fname}
                                helperText={errors.fname && errors.fname.message}
                                defaultValue={newPatient.fname}

                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="lname"
                                autoComplete="off"
                                id="lname"
                                inputRef={register({
                                    required: "กรุณากรอกนามสกุลด้วย"
                                })}
                                label="นามสกุล *"
                                variant="outlined"
                                fullWidth
                                defaultValue={newPatient.lname}
                                helperText={errors.lname && errors.lname.message}
                                error={errors.lname}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="lname_old"
                                autoComplete="off"
                                id="lname_old"
                                inputRef={register}
                                label="นามสกุลเดิม"
                                variant="outlined"
                                fullWidth
                                defaultValue={newPatient.lname_old}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                id="gender"
                                options={
                                    ['ชาย', 'หญิง', 'ไม่ระบุ']
                                }
                                defaultValue={newPatient.gender}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="gender"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกเพศด้วย"
                                        })}
                                        variant="outlined"
                                        label="เพศ"
                                        helperText = {
                                            errors.gender && errors.gender.message
                                        }
                                        error={errors.gender}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                options={day.map(d => (d.name))}
                                defaultValue={newPatient.birth_day}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="birth_day"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกวันเกิดด้วยค่ะ"
                                        })}
                                        variant="outlined"
                                        label="วันเกิด"
                                        helperText={errors.birth_day && errors.birth_day.message}
                                        error={errors.birth_day}
                                    />

                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                options={month.map(m => (m.name))}
                                defaultValue={birth_month}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="birth_month"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกเดือนเกิดด้วยค่ะ"
                                        })}
                                        variant="outlined"
                                        label="เดือนเกิด"
                                        helperText={errors.birth_month && errors.birth_month.message}
                                        error={errors.birth_month}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                options={year.map(y => (y.name))}
                                defaultValue={newPatient.birth_year}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="birth_year"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกปีเกิดด้วยค่ะ"
                                        })}
                                        variant="outlined"
                                        label="ปีเกิด"
                                        helperText={errors.birth_year && errors.birth_year.message}
                                        error={errors.birth_year}
                                    />
                                )}
                            />
                        </Grid>



                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                id="statuses"
                                options={
                                    statusesData.map((statuses, index) => (
                                        statuses
                                    ))
                                }
                                defaultValue={newPatient.statuses}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="statuses"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกสถานะภาพด้วย"
                                        })}
                                        variant="outlined"
                                        label="สถานะภาพ"
                                        helperText={
                                            errors.statuses && errors.statuses.message
                                        }
                                        error={errors.statuses}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                options={educations.map((education) => {
                                    return education.name_tha;
                                })}
                                defaultValue={newPatient.educational}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="educational"
                                        name="educational"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกวุฒิการศึกษาด้วย"
                                        })}
                                        variant="outlined"
                                        label="วุฒิการศึกษา"
                                        helperText={
                                            errors.educational && errors.educational.message
                                        }
                                        error={errors.educational}
                                        autoComplete="off"

                                    />
                                )
                                }
                            />

                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                //freeSolo
                                options={careerPrimarys.map(careerPrimary => (
                                    careerPrimary.name_tha
                                )
                                )}
                                defaultValue={newPatient.career}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="career"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกอาชีพหลักด้วย"
                                        })}
                                        variant="outlined"
                                        label="อาชีพหลัก"
                                        helperText={
                                            errors.career && errors.career.message
                                        }
                                        error={errors.career}
                                    />)
                                }
                            />

                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                options={
                                    careerSecondarys.map(careerSecondary => (
                                        careerSecondary.name_tha
                                    ))
                                }
                                //freeSolo
                                defaultValue={newPatient.career_desc}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="career_desc"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกรายละเอียดอาชีพด้วย"
                                        })}
                                        variant="outlined"
                                        label="รายละเอียดอาชีพ"

                                        helperText={
                                            errors.career_desc && errors.career_desc.message
                                        }
                                        error={errors.career_desc}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                id="blood_type"
                                options={bloods.map((blood) => (blood.name_tha))}
                                defaultValue={newPatient.blood_type}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="blood_type"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกหมู่เลือดด้วย"
                                        })}
                                        variant="outlined"
                                        label="หมู่เลือด"
                                        helperText={
                                            errors.blood_type && errors.blood_type.message
                                        }
                                        error={errors.blood_type}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutoComplete
                                id="drug_allergy"
                                freeSolo
                                options={drugAllergyData.map(drug => (drug))}
                                defaultValue={newPatient.drug_allergy}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="drug_allergy"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกประวัติการแพ้ยาด้วย"
                                        })}
                                        variant="outlined"
                                        label="ประวัติการแพ้ แพ้ยา ระบุสิ่งที่แพ้"
                                        helperText={
                                            errors.drug_allergy && errors.drug_allergy.message
                                        }
                                        error={errors.drug_allergy}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                id="nationality"
                                options={nations.map(nation => nation.name_tha)}
                                defaultValue={newPatient.nationality}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="nationality"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกสัญชาติด้วย"
                                        })}
                                        variant="outlined"
                                        label="สัญชาติ"
                                        helperText={
                                            errors.nationality && errors.nationality.message
                                        }
                                        error={errors.nationality}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                id="race"
                                options={races.map(race => race.name_tha)}
                                defaultValue={newPatient.race}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="race"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกเชื้อชาติด้วย"
                                        })}
                                        variant="outlined"
                                        label="เชื้อชาติ"
                                        helperText={
                                            errors.race && errors.race.message
                                        }
                                        error={errors.race}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                id="religion"
                                options={regionals.map(regional=>(regional.name_tha))}
                                defaultValue={newPatient.religion}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="religion"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกศาสนาด้วย"
                                        })}
                                        variant="outlined"
                                        label="ศาสนา"
                                        helperText={
                                            errors.religion && errors.religion.message
                                        }
                                        error={errors.religion}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="father_name"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกชื่อ-สกุลบิดา"
                                })}
                                variant="outlined"
                                label="ชื่อ-สกุลบิดา"
                                defaultValue={newPatient.father_name}
                                helperText={
                                    errors.father_name && errors.father_name.message
                                }
                                error={errors.father_name}
                                autoComplete="off"

                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="mother_name"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกชื่อ-สกุลมารดาด้วย"
                                })}
                                variant="outlined"
                                label="ชื่อ-สกุลมารดา"
                                defaultValue={newPatient.mother_name}
                                autoComplete="off"
                                helperText={
                                    errors.father_name && errors.father_name.message
                                }
                                error={errors.father_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="spouse"
                                fullWidth
                                inputRef={register({
                                    //required: "กรุณากรอกชื่อ-สกุลคู่สมรสด้วย"
                                })}
                                variant="outlined"
                                label="ชื่อ-สกุลคู่สมรส"
                                defaultValue={newPatient.spouse}
                                autoComplete="off"
                                helperText={
                                    errors.spouse && errors.spouse.message
                                }
                                error={errors.spouse}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="symptom"
                                autoComplete="off"
                                id="symptom"
                                inputRef={register({
                                    required: "กรุณากรอกอาการที่ต้องการพบแพทย์ด้วย"
                                })}
                                label="อาการที่ต้องการพบแพทย์"
                                variant="outlined"
                                fullWidth
                                defaultValue={newPatient.symptom}
                                helperText={
                                    errors.symptom && errors.symptom.message
                                }
                                error={errors.symptom}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={4}>
                            <AutoComplete
                                options={locations.map(location => (location.name))}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="location_name"
                                        autoComplete="off"
                                        id="location_name"
                                        inputRef={register}
                                        label="ห้องตรวจ"
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={newPatient.location_name}
                                    />
                                )
                                }
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={4}>
                            <TextField
                                id="examination_date"
                                type="date"
                                name="examination_date"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกวันที่ต้องการตรวจด้วย",
                                })}
                                variant="outlined"
                                label="วันต้องการตรวจ *"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                defaultValue={newPatient.examination_date}
                                helperText={errors.examination_date && errors.examination_date.message}
                                error={errors.examination_date}
                                
                            />
                        </Grid> */}


                        <RightTreatmentFormComponent right_treatment={newPatient.right_treatment}
                            right_treatment_desc={newPatient.right_treatment_desc}
                            useFormMethods={frm} />

                        <Grid item xs={12} sm={12}>
                            <input accept="image/*" name="photo" ref={register({
                                required: newPatient.photo ? false : "กรุณาระบุภาพถ่าย"
                            })} style={{ display: "none" }} id="icon-button-file" type="file" />
                            <label htmlFor="icon-button-file">
                                <span>ภาพถ่ายบัตรประชาชน</span>
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                                {errors.photo && <div style={{ paddingTop: "1px", color: "red" }}>{errors.photo.message}</div>}
                            </label>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <input accept="image/*" name="photo2" ref={register({
                                required: newPatient.photo2 ? false : "กรุณาระบุภาพถ่าย"
                            })} style={{ display: "none" }} id="icon-button-file2" type="file" />
                            <label htmlFor="icon-button-file2">
                                <span>ภาพถ่ายใบหน้ายืนยันตัวตน</span>
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                                {errors.photo2 && <div style={{ paddingTop: "1px", color: "red" }}>{errors.photo2.message}</div>}
                            </label>
                        </Grid>
                    </Grid>

                    <Typography variant="h5" component="h1" gutterBottom align="center"
                        className="bg-green-600 text-white p-2" style={{ marginTop: "1จpx" }}>ข้อมูลการติดต่อ</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="address"
                                name="address"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกบ้านเลขที่ด้วย"
                                })}
                                variant="outlined"
                                label="บ้านเลขที่"
                                defaultValue={newPatient.address}
                                autoComplete="off"
                                helperText={
                                    errors.address && errors.address.message
                                }
                                error={errors.address}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="road"
                                name="road"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกถนนด้วย"
                                })}
                                variant="outlined"
                                label="ถนน"
                                defaultValue={newPatient.road}
                                autoComplete="off"
                                helperText={
                                    errors.road && errors.road.message
                                }
                                error={errors.road}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                id="soi"
                                name="soi"
                                fullWidth
                                inputRef={register({
                                    //required: "กรุณากรอกซอยด้วย"
                                })}
                                variant="outlined"
                                label="ซอย"
                                defaultValue={newPatient.soi}
                                autoComplete="off"
                                helperText={
                                    errors.soi && errors.soi.message
                                }
                                error={errors.soi}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="moo"
                                name="moo"
                                fullWidth
                                inputRef={register({
                                    //required: "กรุณากรอกหมู่ด้วย"
                                })}
                                variant="outlined"
                                label="หมู่"
                                defaultValue={newPatient.moo}
                                autoComplete="off"
                                helperText={
                                    errors.moo && errors.moo.message
                                }
                                error={errors.moo}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                onChange={(event: any, newValue: any) => {
                                    getProvinceCode(newValue);

                                }}
                                options={
                                    provinces.map(province => {
                                        return province.name_tha
                                    })
                                }
                                defaultValue={newPatient.province}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="province"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกจังหวัดด้วย"
                                        })}
                                        variant="outlined"
                                        label="จังหวัด"
                                        helperText={
                                            errors.province && errors.province.message
                                        }
                                        error={errors.province}
                                    />}
                            />

                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {
                                loadingAmper
                                    ? <div>loading</div>
                                    : <AutoComplete
                                        onChange={(e: any, newValue: any) => {
                                            getAmperCode(newValue);
                                        }}
                                        options={ampers.map(amper => amper.name_tha)}
                                        defaultValue={newPatient.amper}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="amper"
                                                name="amper"
                                                fullWidth
                                                inputRef={register({
                                                    required: "กรุณากรอกอำเภอ/เขต ด้วย"
                                                })}
                                                variant="outlined"
                                                label="อำเภอ/เขต"
                                                helperText={
                                                    errors.amper && errors.amper.message
                                                }
                                                error={errors.amper}
                                            />
                                        )}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                options={tambols.map(tambol => (tambol.name_tha))}
                                defaultValue={newPatient.tambol}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="tambol"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกตำบล/แขวงด้วย"
                                        })}
                                        variant="outlined"
                                        label="ตำบล/แขวง"
                                        helperText={
                                            errors.tambol && errors.tambol.message
                                        }
                                        error={errors.tambol}
                                    />
                                )}
                            />

                        </Grid>


                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="post_code"
                                name="post_code"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกรหัสไปรษณีย์ด้วย"
                                })}
                                variant="outlined"
                                label="รหัสไปรษณีย์"
                                defaultValue={newPatient.post_code}
                                autoComplete="off"
                                helperText={
                                    errors.post_code && errors.post_code.message
                                }
                                error={errors.post_code}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="mobile"
                                name="mobile"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกเบอร์โทรศัพท์ด้วยค่ะ",
                                    pattern: {
                                        value: /^[0-9]+[0-9]+[0-9]+[-]+[0-9]+[0-9]+[0-9]+[-]+[0-9]+[0-9]+[0-9]+[0-9]$/i,
                                        message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง"
                                    },
                                })}
                                InputProps={
                                    {
                                        inputComponent: TextMaskCustom as any,
                                        inputProps: { masks: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/ , '-', /\d/, /\d/, /\d/, /\d/] }
                                    }
                                }
                                variant="outlined"
                                label="โทรศัพท์ มือถือ (SMS)"
                                defaultValue={newPatient.mobile}
                                autoComplete="off"
                                helperText={errors.mobile && errors.mobile.message}
                                error={errors.mobile}

                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h5" component="h1" gutterBottom align="center"
                        className="bg-green-600 p-2 text-white" style={{ marginTop: "15px" }}>ข้อมูลผู้ที่ติดต่อได้กรณีฉุกเฉิน</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="emergency_fname"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกชื่อญาติหรือผู้ที่ติดต่อได้ในกรณีฉุกเฉินด้วย"
                                })}
                                variant="outlined"
                                label="ชื่อญาติหรือผู้ที่ติดต่อได้ในกรณีฉุกเฉิน"
                                defaultValue={newPatient.emergency_fname}
                                autoComplete="off"
                                helperText={
                                    errors.emergency_fname && errors.emergency_fname.message
                                }
                                error={errors.emergency_fname}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="emergency_lname"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกสกุลญาติหรือผู้ที่ติดต่อได้ในกรณีฉุกเฉินด้วย"
                                })}
                                variant="outlined"
                                label="สกุลญาติหรือผู้ที่ติดต่อได้ในกรณีฉุกเฉิน"
                                defaultValue={newPatient.emergency_lname}
                                autoComplete="off"
                                helperText={
                                    errors.emergency_lname && errors.emergency_lname.message
                                }
                                error={errors.emergency_lname}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="emergency_call"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกโทรศัพท์ผู้ที่ติดต่อได้ในกรณีฉุกเฉินด้วย"
                                })}
                                variant="outlined"
                                label="โทรศัพท์ผู้ที่ติดต่อได้ในกรณีฉุกเฉิน"
                                defaultValue={newPatient.emergency_call}
                                autoComplete="off"
                                helperText={
                                    errors.emergency_call && errors.emergency_call.message
                                }
                                error={errors.emergency_call}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="emergency_relate"
                                fullWidth
                                inputRef={register({
                                    required: "กรุณากรอกผู้ติดต่อฉุกเฉินเกี่ยวข้องเป็นด้วย"
                                })}
                                variant="outlined"
                                label="ผู้ติดต่อฉุกเฉินเกี่ยวข้องเป็น"
                                autoComplete="off"
                                defaultValue={newPatient.emergency_relate}
                                helperText={
                                    errors.emergency_relate && errors.emergency_relate.message
                                }
                                error={errors.emergency_relate}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" component="span"> กรณีไม่มีญาติหรือไม่รู้สึกตัวให้ระบุ </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="is_no_relatives"
                                        color="primary"
                                        inputRef={register}
                                        defaultChecked={newPatient.is_no_relatives}
                                    />
                                }
                                label="ไม่มีญาติ"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="is_unconscious"
                                        color="primary"
                                        inputRef={register}
                                        defaultChecked={newPatient.is_unconscious}
                                    />
                                }
                                label="ไม่รู้สึกตัว"
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <AutoComplete
                                id="relative_house_address"
                                freeSolo
                                options={['บ้านเดียวกัน']}
                                defaultValue={newPatient.relative_house_address}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="relative_house_address"
                                        fullWidth
                                        inputRef={register({
                                            required: "กรุณากรอกที่อยู่ญาติที่ติดต่อได้สะดวกด้วย"
                                        })}
                                        variant="outlined"
                                        label="ที่อยู่ญาติที่ติดต่อได้สะดวก"
                                        helperText={
                                            errors.relative_house_address && errors.relative_house_address.message
                                        }
                                        error={errors.relative_house_address}
                                    />
                                )}
                            />
                        </Grid>


                    </Grid>
                    <Grid container className="mt-1">
                        <Grid item xs={12} sm={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled= {loading ? true : false}
                                style={{
                                    transform: loading ? "scale(1.1)" : "",
                                    translate: "transform 0.3s",
                                    cursor: loading ? "not-allowed" : "pointer"
                                    
                                }}
                            >บันทึก{loading && "...."}</Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button type="button"
                                onClick={() => { history.goBack() }}
                                variant="contained"
                            >กลับสู่หน้าหลัก</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            {/* <DialogComponent open={open} setOpen={setOpen}
                redirect={`${clientPath}/`}
                message={`รหัส: ${newPatient.gen_code} `} 
                genCode = {newPatient.gen_code}
                /> */}

            {open ? <OtpComponent open={open} setOpen={setOpen} /> : null}
            <AlertDupRid setOpenRidDup={setOpenRidDup} open={openRidDup} />
        </Container>
    )
}




export default NewRegisterFormComponent;