import axios from 'axios';
import { apiPath } from '../../config/api'
import { LIST_LOCATION } from './types';

const list = (payload: Location[])=>{
    return {
        type: LIST_LOCATION,
        payload
    }
}

export const listAction = ()=>{
    return async (dispatch: any)=>{
        const locations =  await axios.get(`${apiPath}/location/?is_private=0`);
        
        dispatch(list(locations.data));
    }
}