import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    TextField, Dialog
    , DialogContent, DialogTitle, DialogActions
    , Button
} from '@material-ui/core';
import { useOtpCode } from '../../hooks/useOtpCode';
import { ReducerType } from '../../store/types';
import { useHistory } from 'react-router-dom';
import { clientPath, host } from '../../config/api';
import QRCode from 'react-qr-code';
const OtpSuccess = ({ genCode }: { genCode: string }) => {
    const history = useHistory();
    const { prename, fname, lname } = useSelector((result: ReducerType) => result.newPatientReducer.newPatient)

    return (
        <div>
            <DialogTitle style={{textAlign: "center"}}>QRCODE</DialogTitle>
            <DialogContent style={{ margin: "auto", width: "100%",textAlign: "center" }}>
                {genCode &&
                    <div>
                        <i className="fa-light fa-circle-check" />
                        <p style={{
                            textAlign: "center",
                            marginTop: "1rem"
                        }}>บันทึกสำเร็จ</p>
                        <div style={{display: "flex",justifyContent: "center"}}>
                        <QRCode
                            style={{border: "1px solid red"}}
                            //value={`${host}/registration/build/new-register-profile/${genCode}`} 
                            value={`${genCode}`}
                        />
                        </div>
                    </div>
                }
                <p>QR Code: {genCode}</p>
                <p>{prename}{fname} {lname}</p>
                <p>หมายเหตุ: บันทึกหน้าจอหรือถ่ายภาพ QR Code หน้าจอติดต่องานเวชระเบียนและสถิติ 
                    เพื่อรับบัตรโรงพยาบาลโดยแสดงบัตรประชาชนและ QR Code</p>
                <p style={{
                    textAlign: "center",
                    margin: "1rem auto",
                    cursor: "pointer", color: "blue", fontWeight: "bold"
                }} onClick={() => history.push(`${clientPath}/`)}>กลับสู่หน้าหลัก</p>

            </DialogContent>



        </div>)
}

const OtpComponent = ({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) => {
    const history = useHistory();
    const [otpCode, setOtpCode] = useState('');
    const { newPatient } = useSelector((state: ReducerType) => state.newPatientReducer);
    const { checkOtp, reSendOtp, isSuccess, isNewOtpSuccess, isLoading, error } = useOtpCode();
    const [countdown, setCountdown] = useState(300);

    const callCountdown = () => {
        const intervalId = setInterval(() => {
            setCountdown((countdown) => {
                if (countdown === 0) {
                    clearInterval(intervalId);
                    return 0;
                }
                return countdown - 1
            });
        }, 1000)
    }
    useEffect(() => {
        callCountdown();
    }, []);
    return (
        <div>
            <Dialog open={open} fullWidth>
                {!isSuccess
                    ? (
                        <div>
                            <DialogTitle>กรุณากรอกรหัส OTP</DialogTitle>
                            <DialogContent>
                                <TextField
                                    fullWidth variant="outlined"
                                    name="otp_code"
                                    placeholder="OTP"
                                    onChange={(e) => setOtpCode(e.target.value)}
                                    value={otpCode}
                                    style={{ marginBottom: "1rem" }}
                                />
                                {error && <p>{error}</p>}
                                {isSuccess &&
                                    <div>
                                        <p>บันทึกข้อมูลสำเร็จสำเร็จ</p>
                                    </div>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div className="flex justify-start border-2 p-2">
                                    <div>
                                        รหัส OTP จะหมดอายุใน
                                    </div>
                                    <div className="text-blue-500 pl-4">
                                        {Math.floor(countdown / 60)}: {(countdown % 60).toString().padStart(2, '0')}
                                    </div>
                                </div>
                                <Button variant="contained" onClick={
                                    () => checkOtp(newPatient.id, otpCode)
                                }>{isLoading ? 'กำลังบันทึก...' : 'บันทึก'}</Button>
                                <Button
                                    onClick={() => {
                                        reSendOtp(newPatient.id);
                                        setCountdown(300);
                                    }}
                                    style={{ marginLeft: "1rem" }} variant="contained">ขอ OTP ใหม่</Button>
                                <Button onClick={
                                    () => {
                                        history.push('/');
                                    }
                                }>กลับสู่หน้าหลัก</Button>
                            </DialogActions>
                        </div>
                    )
                    : <OtpSuccess genCode={newPatient.gen_code} />}
                    

            </Dialog>
        </div>
    );
}

export default OtpComponent;
