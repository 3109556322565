import { Container } from '@material-ui/core';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { orange, lightGreen } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            marginTop: theme.spacing(5),
            height: theme.spacing(14),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 25,
            padding: "10px 10px 10px 20px",
        },
        link: {
            textDecoration: "none"
        },
        paperMan: {
            marginTop: theme.spacing(5),
            padding: 20,
        },
        manualHead: {
            fontSize: 24,
            textAlign: "center",
            fontWeight: "bold"
        },
        manualList: {
            fontSize: 16,
            listStyleType: "decimal",
            marginBottom: 15,
            marginLeft: 10,
        },
        avatar: {
            backgroundColor: lightGreen[500],
            marginRight: 5,
        },
        banner: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            backgroundColor: orange[400],
            flexDirection: "column",

        }
    })
})
const Condition = () => {
    const classes = useStyles();
    return (
        <div className="container max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">
            <div style={{ fontSize: 18 }}>
                <div className="text-center bg-green-100">
                    เงื่อนไขการใช้งาน
                </div>
            </div>
            <div className="p-10">
                <ul className={classes.manualList}>
                    <li>ทำบัตรใหม่</li>
                    <ul className={classes.manualList}>
                        <li>กรอกข้อมูลในระบบ</li>
                        <li>เมื่อมาถึงโรงพยาบาล ให้นำบัตรประชาชนติดต่องานเวชระเบียนและสถิติ เพื่อบันทึกบัตรใหม่ และพิมพ์ใบนำทาง (Visit Slip)
                        </li>
                    </ul>
                    <li>แบบฟอร์มลงทะเบียนยืนยันการเข้าตรวจ (Check in)</li>
                    <ul className={classes.manualList}>
                        <li>แบบฟอร์มลงทะเบียนยืนยันการเข้าตรวจ ใช้สำหรับคนไข้ที่มีประวัติ เลขประจำตัวผู้ป่วย (HN) แล้วเท่านั้น</li>
                        <li>กรอกข้อมูลเลขประจำตัวผู้ป่วย (HN), เลขบัตรประชาชน, วันที่ต้องการตรวจ, Email(ถ้ามี), เบอร์โทรศัพท์มือถือ, ภาพถ่ายบัตรนัด และสิทธิ์การรักษาพยาบาล ครั้งนี้ ให้ครบถ้วน</li>
                        <li>กดปุ่มบันทึก</li>
                    </ul>
                    <li>ตรวจสอบสถานะทำบัตรใหม่</li>
                    <ul className={classes.manualList}>
                        <li>กรอกข้อมุลบัตรประชาชนและกดปุ่มตรวจสอบ</li>
                        <li>ข้อความแจ้งเตือน</li>
                        <ul className={classes.manualList}>
                            <li>ไม่มีประวัติ</li>
                            <li>ลงทะเบียนแล้ว ยังไม่ส่งไประบบ HO</li>
                            <li>ลงทะเบียนแล้ว ส่งไประบบ HO แล้ว</li>
                        </ul>
                    </ul>
                    <li>ตรวจสอบแบบฟอร์มลงทะเบียนยืนยันการเข้าตรวจ (Check in)</li>
                    <ul className={classes.manualList}>
                        <li>กรอกข้อมูล HN และกดปุ่มตรวจสอบ</li>
                        <li>ข้อความแจ้งเตือน</li>
                        <ul className={classes.manualList}>
                            <li>คุณยังไม่ได้ลงทะเบียน</li>
                            <li>ลงทะเบียนแล้วยังไม่ได้เข้าระบบ HO</li>
                            <li>ลงทะเบียนแล้วเข้าระบบ HO แล้ว</li>
                        </ul>
                    </ul>
                </ul>
            </div>
        </div>

    )
}

export default Condition;