import axios from 'axios';
import { CareerPrimary, CAREERPRIMARYLIST } from './types';
import { apiPath } from '../../config/api'
const list = (payload: CareerPrimary[])=>{
    return {
        type: CAREERPRIMARYLIST,
        payload
    }
}

export const listCareerPrimaryAction = ()=>{
    return async (dispatch: any)=>{ 
        const payload = await axios.get<CareerPrimary[]>(`${apiPath}/career-primary/`);
        dispatch(list(payload.data))
    }
}