import React,{useState,useContext} from 'react';
import { TextField,Button,Container,Grid,Paper } from '@material-ui/core';
import { makeStyles,Theme,createStyles } from '@material-ui/core/styles';
import { listOneNewPatientByRidAction } from '../store/new-patient/actions';
import { useSelector,useDispatch } from 'react-redux';
import { ReducerType } from '../store/types';
import DialogComponent from '../components/DialogComponent';
import { RouterContext } from '../hooks/useRouterProvider';
import { History } from 'history';
import axios from 'axios';
import { apiPath } from '../config/api';
import { NavLink } from 'react-router-dom';
const useStyles = makeStyles((theme: Theme) => (
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1)
        },
        button: {
            marginTop: theme.spacing(1)
        }
    })
))
const CheckNewRegister = ({history }: {history: History}) => {
    
    const classes = useStyles();
    const [rid,setRid] = useState("");
    const [open,setOpen] = useState(false);
    const [message,setMessage] = useState("");
    const dispatch = useDispatch();
    const { newPatients } = useSelector((selector: ReducerType)=>(selector.newPatientReducer));
    const handleSearch = async () => {
        const patient = await axios.get(`${apiPath}/new-patient/?rid=${rid}`);
        if(patient.data[0]){
            setMessage(`ท่านลงทะเบียนในระบบผู้ป่วยออนไลน์แล้ว ติดต่องานเวชระเบียนและสถิติ 
            เพื่อรับบัตรโรงพยาบาล​ โดยแสดงบัตรประชาชน และQR Code`);
            if(patient.data[0].send_to_ho=="1"){
                setMessage("ท่านลงทะเบียนผู้ป่วยใหม่เรียบร้อยแล้ว");
            }
        }else{
            setMessage("ท่านไม่ได้ลงทะเบียนในระบบผู้ป่วยใหม่ออนไลน์หากท่านต้องการลงทะเบียนกดเลือก ลงทะเบียน");
            
        }
        setOpen(true);
    }
    
    return (
        <Container maxWidth="xs">
            <Paper className={classes.paper}>
                <h1>ตรวจสอบการทำบัตรใหม่</h1>
                <Grid container spacing={1} style={{marginTop:"10px"}}>
                    <Grid item xs={12} sm={12}>
                        <TextField 
                            name="rid"
                            autoComplete="off"
                            onChange = {(e: React.ChangeEvent<HTMLInputElement>)=>{
                                setRid(e.target.value);
                            }}
                            variant="outlined"
                            fullWidth
                            label="เลขที่บัตรประชาชน"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
            <Button type="button" variant="contained" onClick={handleSearch}>ค้นหา</Button>
            &nbsp;&nbsp;<Button type="button" variant="contained" onClick={()=>{
                history.goBack();
            }}>กลับ</Button>
            </Grid>
        </Grid>
            </Paper>
            <DialogComponent open={open} setOpen={setOpen} redirect="" message={message} />
        </Container>
        
    )
}

export default CheckNewRegister;