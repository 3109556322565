import { BloodActionType, BloodState, BLOOD_LIST } from "./types";

const initState = {
    bloods: []
}
const reducer = (state: BloodState=initState,action: BloodActionType)=>{
    switch(action.type){
        case BLOOD_LIST:
            return {
                ...state,
                bloods: action.payload
            }
        default:
            return state;
    }
}

export default reducer;