export interface Race{
    name_tha?: string
}

export interface RaceStateType{
    races: Race[];
    race: Race;
}

export const RACE_LIST_ACTION = "RACE_LIST_ACTION";

interface RaceListAction{
    type: typeof RACE_LIST_ACTION;
    payload: Race[];
}

export type RaceActionType = RaceListAction;