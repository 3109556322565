export interface NewPatient{
    id?: string;
    hn:string;
    prename: string;
    fname: string;
    lname: string;
    lname_old: string;
    gender: string;
    birth_year: string;
    birth_month: string;
    birth_day: string;
    rid: string;
    passport: string;
    address: string;
    road: string;
    soi: string;
    moo: string;
    tambol: string;
    amper: string;
    province: string;
    post_code: string;
    mobile: string;
    statuses: string;
    educational: string;
    career: string;
    career_desc: string;
    blood_type: string;
    drug_allergy: string;
    nationality: string;
    race: string;
    religion: string;
    father_name: string;
    mother_name: string;
    spouse: string;
    emergency_fname: string;
    emergency_lname: string;
    emergency_call: string;
    emergency_relate: string;
    is_no_relatives: boolean;
    is_unconscious: boolean;
    relative_house_address: string;
    location_name: string;
    examination_date: Date;
    symptom: string;
    gen_code: string;
    right_treatment: string;
    right_treatment_desc: string;
    isNonThai: boolean;
    photo: string;
    photo2: string;
    send_to_ho?: string;
    pre_code?: string;
    gender_code?: string;
    tambol_code?: string;
    amper_code?: string;
    province_code?: string;
    marry_code?: string;
    edu_code?: string;
    pri_code?: string;
    sec_code?: string;
    blood_code?: string;
    nation_code?: string;
    race_code?: string;
    reg_code?: string;
    active?: string;
}

export interface NewPatientState{
    newPatients: NewPatient[];
    dataSearch: NewPatientSearch;
    newPatient: NewPatient;
    count: number;
}

export interface NewPatientSearch{
    gen_code?: string;
    fname?: string;
    lname?: string;
}

export const ADD_NEW_PATIENT = "ADD_NEW_PATIENT";
export const UPDATE_NEW_PATIENT = "UPDATE_NEW_PATIENT";
export const LIST_NEW_PATIENT = "LIST_NEW_PATIENT";
export const SET_DATA_SEARCH_NEW_PATIENT = "SET_DATA_SEARCH_NEW_PATIENT";
export const LIST_ONE_NEW_PATIENT = "LIST_ONE_NEW_PATIENT";
export const CLEAR_ONE_NEW_PATIENT = "CLEAR_ONE_NEW_PATIENT";
export const CLEAR_NEW_PATIENT = "CLEAR_NEW_PATIENT";

export interface AddNewPatientAction{
    type: typeof ADD_NEW_PATIENT;
    payload: NewPatient
}

export interface UpdateNewPatientAction{
    type: typeof UPDATE_NEW_PATIENT;
    payload: NewPatient
}

export interface ListNewPatientAction{
    type: typeof LIST_NEW_PATIENT;
    payload: {
        newPatients: NewPatient[],
        count: number
    }
}

export interface SetDataNewPatientAction{
    type: typeof SET_DATA_SEARCH_NEW_PATIENT;
    payload: NewPatientSearch;
}

export interface ListOneNewPatientAction{
    type: typeof LIST_ONE_NEW_PATIENT;
    payload: NewPatient;
}

export interface ClearOneNewPatientAction{
    type: typeof CLEAR_ONE_NEW_PATIENT;
}

export interface ClearNewPatientAction{
    type: typeof CLEAR_NEW_PATIENT;
}



export type NewPatientActionType = AddNewPatientAction | ListNewPatientAction 
| SetDataNewPatientAction | ListOneNewPatientAction | ClearOneNewPatientAction | 
UpdateNewPatientAction | ClearNewPatientAction


