import React, { ReactElement } from 'react'
import RegisterListComponent from '../components/RegisterListComponent'
interface Props {
    
}

export default function ManageRegister({}: Props): ReactElement {
    return (
        <>
          <RegisterListComponent />  
        </>
    )
}
