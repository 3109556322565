import { Paper, Container, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { clientPath } from '../config/api';
const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        p: {
            marginTop: "20px",
            fontSize: "16px",
            marginBottom: "10px",
            color: "teal",
            fontWeight: "bold",
        },
        mainImg: {
            marginBottom: 10,
        },
        img: {
            margin: "0 auto",
        }
    })
})

export default function UserManual() {
    const classes = useStyles();
    return (
        <Container>
            <Paper>
                <p style={{ textAlign: "center", padding: "10px", fontSize: "20px", backgroundColor: "teal", color: "white" }}>คู่มือการใช้งานระบบลงทะเบียนผู้ป่วยใหม่ออนไลน์</p>
                <div className="p-4">
                    <p className={classes.p}>1. คลิก <NavLink style={{color: "blue"}} to={`${clientPath}/new-register`}>ระบบลงทะเบียนผู้ป่วยออนไลน์</NavLink>  หรือสแกน QR-Code</p>

                    <div className={classes.mainImg}><img className={classes.img} style={{width: "20%",height: "20%"}} src={process.env.PUBLIC_URL + "/img_manual/1.1.png"} /></div>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/1.2.png"} /></div>

                    <p className={classes.p}>2. กดเลือก “ทำบัตรใหม่” </p>
                    <div className={classes.mainImg}><img style={{width: "30%",height: "30%"}} className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/2.1.png"} /></div>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/2.2.png"} /></div>



                    <p className={classes.p}>3. อ่านเงื่อนไขข้อตกลงนโยบายข้อมูลส่วนบุคคล โรงพยาบาลศรีนครินทร์ คณะแพทยศาสตร์ มหาวิทยาลัยขอนแก่น จากนั้นกดเลือก “ยินยอม” และกดเลือก “ยืนยัน”</p>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/3.png"} /></div>

                    <p className={classes.p}>4. กรอกข้อมูลเพื่อทำบัตรตรวจโรคโรงพยาบาลศรีนครินทร์ ตามความเป็นจริง </p>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/4.1.png"} /></div>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/4.2.png"} /></div>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/4.3.png"} /></div>

                    <p className={classes.p}>5. ตรวจสอบความถูกต้องของข้อมูล จากนั้นกดเลือก “บันทึก”</p>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/5.png"} /></div>

                    <p className={classes.p}>6. ระบบทำการส่ง SMS รหัส OTP ไปยังเบอร์โทรศัพท์มือถือ จากนั้นให้นำรหัส OTP มากรอกเข้าระบบ และกดเลือก “บันทึก”</p>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/6.1.png"} /></div>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/6.2.png"} /></div>

                    <p className={classes.p}>7. ระบบทำการแจ้งสถานะการลงทะเบียนเสร็จสิ้น ให้ท่านบันทึกหน้าจอ QR Code เพื่อแสดงกับเจ้าหน้าที่</p>
                    <div className={classes.mainImg}><img className={classes.img} src={process.env.PUBLIC_URL + "/img_manual/7.1.png"} /></div>

                    <p className={classes.p}>8. การลงทะเบียนเสร็จสิ้น ท่านสามารถขอรับบัตรประจำตัวโรงพยาบาลได้ โดยนำบัตรประชาชนและ QR Code ติดต่อที่ งานเวชระเบียนและสถิติ</p>
                </div>
            </Paper>
        </Container>
    )
}
