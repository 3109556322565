import axios from 'axios';
import { TambolActionType,Tambol,TAMBOL_LIST }  from './types';
import { apiPath } from '../../config/api';
const list = (payload: Tambol[]):TambolActionType=>{
    return{
        type: TAMBOL_LIST,
        payload
    }
}

export const listTambolAction = (amperCode: string)=>{
    return async (dispatch: any)=>{
        
        const ampers = await axios.get(`${apiPath}/tambol/?amper_code=${amperCode}`)

        dispatch(list(ampers.data));
    }
}
