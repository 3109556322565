import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { NavLink } from "react-router-dom";
import { clientPath } from "../../config/api";
export default function AlertDupRid({open,setOpenRidDup}:{open: boolean,setOpenRidDup:(data: boolean)=>void}) {
    const handleClose = () => {
        setOpenRidDup(false);
    }
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>ข้อความ</DialogTitle>
            <DialogContent>ท่านได้ลงทะเบียนผู้ป่วยใหม่ออนไลน์แล้ว สามารถตรวจสอบข้อมูลได้ที่ 
                <NavLink to={`${clientPath}/check-new-register`}>คลิก</NavLink>
            </DialogContent>
            <DialogActions>
            <NavLink to={`${clientPath}/`}>กลับสู่หน้าหลัก</NavLink>
            </DialogActions>
        </Dialog>
    )
}
