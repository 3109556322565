import { ProvinceActionType, ProvinceState } from "./types";
const initState = {
    provinces: []
}
const reducer = (state:ProvinceState=initState,action: ProvinceActionType)=>{
    switch(action.type){
        case "LIST_PROVINCE":
            return {
                ...state,
                provinces: action.payload
            }
        default:
            return state;
    }
}

export default reducer;