import axios from 'axios';
import { apiPath } from '../../config/api';
import { Province, ProvinceActionType,LIST_PROVINCE } from './types';

const list = (payload: Province[]):ProvinceActionType=>{
    return {
        type: LIST_PROVINCE,
        payload
    }
}

export const listProvince = ()=>{
    return async (dispatch:any)=>{
        const provinces = await axios.get<Province[]>(`${apiPath}/province/`);
        dispatch(list(provinces.data));
    }
}