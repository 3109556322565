import React,{createContext} from 'react';
import { BrowserRouter,Route,RouterProps } from 'react-router-dom'
export const RouterContext = createContext({});

export const RouterProvider:React.FC = ({children})=>{
    return(
        <BrowserRouter>
            <Route>
                {
                    (props)=>{
                        return (
                            <RouterContext.Provider value={props}>
                                {children}
                            </RouterContext.Provider>
                        )
                    }
                }
            </Route>
        </BrowserRouter>
    )
}

 