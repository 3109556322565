export interface User{
    username?: string;
    email: string;
    password: string;
    fullnames: string;
    token: string;
    
}

export interface UserState{
    users: User[];
}

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

interface LoginUserAction{
    type: typeof LOGIN_USER,
    payload: User
}

interface LogoutUserAction{
    type: typeof LOGOUT_USER,
    
}

export type UserActionType = LoginUserAction | LogoutUserAction;