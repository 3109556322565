import axios from 'axios';
import { apiPath } from '../../config/api'
import { User, LOGIN_USER, LOGOUT_USER, } from './types';

const loginUserToReducer = (payload:User)=>{
    return {
        type: LOGIN_USER,
        payload
    }
}

const logoutUserToReducer = ()=>{
    return{
        type: LOGOUT_USER
    }
}

export const loginUser = (payload: User)=>{
    return async (dispatch:any)=>{
        
        const token= Math.floor(Math.random() * 100000000000).toString();
        payload.token = token;
        
        const result = await axios.post(`${apiPath}/users/`,{...payload});
        
        //const result = await axios.post(`https://services.kku.ac.th:8443/services/ldap.php`,{email: payload.username,password: payload.password})
        if(result.data.cn){
            const user:User = {
                email: result.data.cn,
                password: "",
                fullnames: result.data.fullnames,
                token
            }  
            dispatch(loginUserToReducer(user));
        }
    }
}

export const logoutUser = ()=>{
    console.log("logoutuser in action");
    return (dispatch:any)=>{
        dispatch(logoutUserToReducer());
    }
}

