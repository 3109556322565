
import { UserActionType,LOGIN_USER,LOGOUT_USER,User } from "./types";

const initUser:User = {
    email: "",
    password: "",
    fullnames: "",
    token: "",
};

export const userReducer = (state:User=initUser,action: UserActionType)=>{
    switch(action.type){
        case LOGIN_USER:
            return {
                ...state,
                email: action.payload.email,
                fullnames: action.payload.fullnames,
                token: action.payload.token
            }
        case LOGOUT_USER:
            return{
                ...state,
                email: "",
                fullnames: "",
                token: "",
            }
        default:
            return state;
    }
}