export interface Education{
    name_tha?: string;


}
export interface EducationStateType{
    educations: Education[];
    education: Education
}

export const LIST_EDUCATION_ACTION = "LIST_EDUCATION_ACTION";

interface ListEducationAction{
    type: typeof LIST_EDUCATION_ACTION;
    payload: Education[];
}

export type EducationActiontype = ListEducationAction;