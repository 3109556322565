import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { match, RouteComponentProps } from 'react-router-dom';
import { History } from 'history'
import NewRegisterFormComponent from '../components/NewRegisterFormComponent';
import NewRegisterListComponent from '../components/NewRegisterListComponent';
import {useDispatch ,useSelector} from 'react-redux';
import { listOneNewPatientAction } from '../store/new-patient/actions';
import {ReducerType} from '../store/types'

import LoadingComponent from '../components/LoadingComponent';
interface Params {
  id: string
}
interface Props {
  match: match<Params>;
  history: History;
}



export default function NewRegisterList({ match, history }: Props): ReactElement {
  const dispatch = useDispatch();
  const {newPatient} = useSelector((selector:ReducerType)=> selector.newPatientReducer)
  
  useEffect(() => {
    if (match.params.id) {
      (async () => {
        await dispatch(listOneNewPatientAction(match.params.id));
      })()
    }
    
  }, [match.params.id]);
  return (
    <React.Fragment>
      {
        match.params.id ? (
            newPatient.id ? (<NewRegisterFormComponent history={history} match={match} />):
            (<LoadingComponent />)
        )  
         : (
            <NewRegisterListComponent />
         )
      }
    </React.Fragment>
  );
}
