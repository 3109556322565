export interface Province{
    id?: number;
    code?: string;
    name_eng?: string;
    name_tha?: string;
}

export const LIST_PROVINCE = "LIST_PROVINCE";
export interface ProvinceState{
    provinces: Province[]
}
interface ListProvinceAction{
    type: typeof LIST_PROVINCE,
    payload: Province[]
}

export type ProvinceActionType = ListProvinceAction