import React, { useState, useContext, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addPatient } from '../store/patient/actions';
import { Patient } from '../store/patient/types';
import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DialogComponent from './DialogComponent';
import MaskedInput from 'react-text-mask';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { clientPath } from '../config/api';
import { RouterContext } from '../hooks/useRouterProvider';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AppointmentFormComponent from './register-components/AppointmentFormComponent';
import NotAppointmentFormComponent from './register-components/NotAppointmentFormComponent';
import RightTreatmentFormComponent from './register-components/RightTreatmentFormComponent';
import TextMaskCustom from './TextMaskCustom';
interface Props {

}

const useStyles = makeStyles((theme: Theme) => (

  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2)
      }
    },

  })
))




const RegisterForm: React.FC = ({ }: Props) => {
  const props = useContext<any>(RouterContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const frm = useForm()
  const { register, handleSubmit, errors, reset } = frm;
  const [loading, setLoading] = useState<boolean>(false);
  const [patientType, setPaitentType] = useState("คนไข้นัด");
  const onSubmit = async (value: Patient) => {
    setLoading(true);
    value.rid = value.rid.replaceAll(' ', '');
    if (value.mobile) { value.mobile = value.mobile.replaceAll(' ', ''); }

    const status = await dispatch(addPatient(value));
    setLoading(false);
    if (status !== null) {
      setOpen(true);
      reset();
    } else {
      alert("กรุณาลองใหม่");
    }
  }

  const handlePatientTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaitentType(e.target.value);
  }


  const getFormatDate = (d: Date) => {
    let year = d.getFullYear();
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    const formatDate = year + "-" + month + "-" + day;
    return formatDate;
  }
  const minD = new Date();
  const maxD = new Date();
  maxD.setDate(maxD.getDate() + 1);

  const minDate = getFormatDate(minD);
  const maxDate = getFormatDate(maxD);


  return (
    <>
      <Paper className="p-4">
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} onClose={() => setOpen(false)}>
          <Alert>บันทึกสำเร็จ</Alert>
        </Snackbar>
        <Typography variant="h5" align="center">แบบฟอร์มลงทะเบียนยืนยันการเข้าตรวจ (Check in)</Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">ประเภทผู้ป่วย</FormLabel>
                <RadioGroup
                  onChange={handlePatientTypeChange}
                  row aria-label="position" name="patient_type" 
                  value={patientType} >
                  <FormControlLabel
                    value="คนไข้นัด"
                    control={<Radio color="primary" />}
                    label="คนไข้นัด"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="คนไข้ไม่ได้นัด"
                    control={<Radio color="primary" />}
                    label="คนไข้ไม่ได้นัด"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <TextField
                name="hn"
                variant="outlined"
                placeholder="HN"
                fullWidth
                inputRef={register({
                  required: "กรุณากรอก HN"
                })}
                label="HN"
                error={errors.hn}
                helperText={errors.hn && errors.hn.message}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField name="rid"
                variant="outlined"
                fullWidth
                inputRef={register({
                  required: "กรุณากรอกเลขบัตรประชาชน",
                  pattern: {
                    value: /^[0-9]+[ ]+[0-9]+[0-9]+[0-9]+[0-9]+[ ]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[ ]+[0-9]+[0-9]+[ ]+[0-9]$/i,
                    message: "กรุณากรอกเลขบัตรประชาขนให้ถูกต้อง"
                  }
                })}

                InputProps={{
                  inputComponent: TextMaskCustom as any,
                  inputProps: { masks: [/\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/] }
                }}

                error={errors.rid}
                helperText={errors.rid && errors.rid.message}
                label="เลขบัตรประชาชน"

              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField name="examination_date"
                variant="outlined"
                fullWidth
                inputRef={register({
                  required: "กรุณากรอกวันที่ต้องการตรวจ",
                  min: {
                    value: minDate,
                    message: "กรุณากรอกช่วงวันที่ให้ถูกต้องด้วย"
                  },
                  max: {
                    value: maxDate,
                    message: "กรุณากรอกช่วงวันที่ให้ถูกต้องด้วย"
                  },
                })}
                InputLabelProps={
                  {
                    shrink: true
                  }
                }
                type="date"
                inputProps={{
                  min: minDate,
                  max: maxDate,
                }}
                error={errors.examination_date}
                
                helperText={errors.examination_date && errors.examination_date.message}
                label="วันที่ต้องการตรวจ *"

              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField name="email"
                variant="outlined"

                fullWidth
                inputRef={register({
                  pattern: {
                    value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    message: "กรุณากรอก Email ให้ถูกด้วย"
                  }
                })}
                label="email"
                error={errors.email}
                helperText={errors.email && errors.email.message}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField name="mobile"
                variant="outlined"
                label="เบอร์โทรศัพท์มือถือ"
                fullWidth
                inputRef={register({
                })}

                InputProps={{
                  inputComponent: TextMaskCustom as any,
                  inputProps: { masks: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] }
                }}
              />
            </Grid>

            {
              patientType === "คนไข้นัด" && <AppointmentFormComponent formProp={frm} />
            }
            {
              patientType === "คนไข้ไม่ได้นัด" && <NotAppointmentFormComponent formProp={frm} />
            }

            <RightTreatmentFormComponent
              right_treatment=""
              right_treatment_desc=""
              useFormMethods={frm} />
            <Grid item xs={6} sm={6}>
              <Button type="submit" fullWidth style={{

                transform: loading ? "scale(1.1)" : "",
                translate: "transform 0.3s",
                cursor: loading ? "not-allowed" : "pointer"
              }} variant="contained">{loading ? "กำลังบันทึก" : "บันทึก"}</Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button type="button"
                fullWidth
                variant="contained"
                onClick={
                  () => {
                    props.history.goBack();
                  }
                }
              >ย้อนกลับ</Button>
            </Grid>
          </Grid>

          <input type="hidden" name="status" defaultValue="ลงทะเบียน" ref={register} />
        </form>
        <DialogComponent open={open} setOpen={setOpen} redirect={`${clientPath}/check-register`} message="บันทึกข้อมูลเรียบร้อยแล้ว" />

      </Paper>
    </>
  )
}

export default RegisterForm;