import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles,makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
interface Props{

}
const useStyles = makeStyles((theme: Theme)=>(
    createStyles({
        root: {
            textAlign: "center"
        }
    })
))

const LoadingComponent = ({}:Props):React.ReactElement=>{
    const classes = useStyles();
    return (
    <React.Fragment>
        <CircularProgress className={classes.root} />
    </React.Fragment>
    )
}

export default LoadingComponent;