import React from 'react';
import MaskedInput from 'react-text-mask';
interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
    masks: any
}
export default function TextMaskCustom(props: TextMaskCustomProps) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={other.masks}
        //mask={[/\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/]}
  
      //placeholderChar={'\u2000'}
      //guide={true}
      //showMask
  
      />
    );
  }