export interface Marry{
    name_tha?: string;
}

export interface MarryStateType{
    marry: Marry;
    marrys: Marry[];
}

export const MARRY_LIST_ACTION = "MARRY_LIST_ACTION";

interface MarryListAction{
    type: typeof MARRY_LIST_ACTION;
    payload: Marry[];
}

export type MarryActionType = MarryListAction;