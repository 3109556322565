import React from 'react';
import { Provider } from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import reducers from './store';
import {RouterProvider} from './hooks/useRouterProvider';
import Routes from './Routes';
import thunk from 'redux-thunk'
import {ThemeProvider,createMuiTheme} from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
const store = createStore(reducers,applyMiddleware(thunk));

const theme = createMuiTheme({
  palette: {
    primary:{
      main: lightGreen[900]
    }
  }
})

function App() {
  return (
    <Provider store={store}>
      <RouterProvider>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </RouterProvider>
    </Provider>
  );
}

export default App;
