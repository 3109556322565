import { Container, Paper } from "@material-ui/core";

export default function AdminManual() {
    return (
        <Container>
            <Paper style={{padding: "15px"}}>
                <iframe style={{width:"100%",height: 600}} src={`${process.env.PUBLIC_URL}/admin_manual.pdf`} />
            </Paper>
        </Container>
    )
}
