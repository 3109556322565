import { RaceActionType, RaceStateType,RACE_LIST_ACTION } from "./types";
const initState = {
    races: [],
    race: {}
}
const reducer = (state: RaceStateType=initState,action: RaceActionType)=>{
    switch(action.type){
        case RACE_LIST_ACTION:
            return {
                ...state,
                races: action.payload
            }
        default: return state;
    }
}

export default reducer;