import { AmperState,AmperActionType, LIST_AMPER } from "./types";
const initState = {
    ampers: []
}
const reducer = (state: AmperState=initState,action: AmperActionType)=>{
    switch(action.type){
        case LIST_AMPER:
            return {
                ...state,
                ampers: action.payload
            }
        default:
            return state
    }
}

export default reducer;