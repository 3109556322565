import axios from 'axios';
import { Action } from 'redux';
import { Race, RACE_LIST_ACTION } from './types';
import { apiPath } from '../../config/api';
const list = (payload: Race[])=>{
    return {
        type: RACE_LIST_ACTION,
        payload
    }
}

export const raceListAction = () => {
    return async (dispatch: (arg: Action)=>void)=>{
        const races = await axios.get(`${apiPath}/races/`);
        dispatch(list(races.data));
    }
}