import { ADD_NEW_PATIENT, CLEAR_NEW_PATIENT, CLEAR_ONE_NEW_PATIENT, LIST_NEW_PATIENT, 
    LIST_ONE_NEW_PATIENT, NewPatient, NewPatientActionType, NewPatientSearch, SET_DATA_SEARCH_NEW_PATIENT, UPDATE_NEW_PATIENT } from "./types"
import axios from 'axios';
import {apiPath} from '../../config/api';

export const add = (payload: NewPatient):NewPatientActionType=>{
    return {
        type: ADD_NEW_PATIENT,
        payload
    }
}

const update = (payload: NewPatient)=>{
    return {
        type: UPDATE_NEW_PATIENT,
        payload
    }
}

const list = (payload: {data: NewPatient[],count: number}):NewPatientActionType=>{
    return {
        type: LIST_NEW_PATIENT,
        payload: {
            newPatients: payload.data,
            count: payload.count
        }
    }
}

const listOne = (payload: NewPatient):NewPatientActionType=>{
    
    return {
        type: LIST_ONE_NEW_PATIENT,
        payload
    }
}

export const setDataSearch = (payload: NewPatientSearch):NewPatientActionType=>{
    return {
        type: SET_DATA_SEARCH_NEW_PATIENT,
        payload
    }
}   

export const clearOne = ():NewPatientActionType=>{
    return {
        type: CLEAR_ONE_NEW_PATIENT
    }   
}

export const clearMany = ():NewPatientActionType=>{
    return{
        type: CLEAR_NEW_PATIENT
    }
}

export const addNewPatientAction = (payload: NewPatient)=>{
    return async (dispatch: any)=>{
        const data = new FormData();
        data.append('photo',payload.photo[0]);
        data.append('photo2',payload.photo2[0]);
        const photo = await axios.post(`${apiPath}/new-patient-photo/`,data);
        payload.photo = photo.data.photo_name_rid;
        payload.photo2 = photo.data.photo_name_pic;
        payload.gen_code = new Date().getTime().toString();
        const result = await axios.post(`${apiPath}/new-patient/`,payload);
            if(result.data){
                //if(result.data.sms){
                    payload.id = result.data.id;
                    dispatch(add(payload));
                //}else{
                 //   alert("blocking");
                //}
            }
    }
}

export const listNewPatientAction = (dataSearch: NewPatientSearch,page: number)=>{
    return async (dispatch: any,getState: any)=>{
        const result = await axios.get(`${apiPath}/new-patient2/?dataSearch=${JSON.stringify(dataSearch)}&page=${page}`,{
            headers:{
                username: getState().userReducer.email,
                token: getState().userReducer.token
            }
        })
        dispatch(list(result.data));
        return result.data
    }
}

export const listOneNewPatientByGencodeAction = (genCode: string) =>{
    return async (dispatch: any) => {
        const newPatient = await axios.get(`${apiPath}/new-patient/?gen_code=${genCode}`);
        
        return dispatch(listOne(newPatient.data[0]));
    }
}

export const listOneNewPatientAction = (id: string)=>{
    return async (dispatch:any,getState: any)=>{
        
        const newPatient = await axios.get<NewPatient[]>(`${apiPath}/new-patient/?id=${id}`,{
            headers:{
                username: getState().userReducer.email,
                token: getState().userReducer.token
            }
        });

        return dispatch(listOne(newPatient.data[0]));

    }
}

export const listOneNewPatientByRidAction = (rid: string) => {
    return async (dispatch: any) => {
        const newPatient = await axios.get(`${apiPath}/new-patient/?rid=${rid}`);
        dispatch(listOne(newPatient.data));
    }
}

export const sendNewPatientToHoAction = (id: string)=>{
    return async (dispatch: any,getState: any)=>{
        const newPatient = await axios.get<NewPatient[]>(`${apiPath}/new-patient/?id=${id}`,{
            headers:{
                username: getState().userReducer.email,
                token:  getState().userReducer.token
            }
        });
        const data = newPatient.data[0];
        const postData = {
            "citizenID": data.rid,
            "patientTitleCode": data.pre_code,
            "patientForname": data.fname,
            "patientSurname": data.lname,
            "genderCode"				: data.gender_code,
            "patientBirthDttm"			: `${data.birth_year}-${data.birth_month}-${data.birth_day}`,
            "patientAddrNo"				: data.address,
            "patientAddrRoad"			: data.road,
            "patientAddrLane"			: data.soi,
            "patientAddrMoo"				: data.moo,
            "patientAddrAreaCode"			: data.tambol_code, 
            "patientAddrDistrictCode"			: data.amper_code,
            "patientAddrProvinceCode"			: data.province_code,
            "patientAddrPinCode"			: data.post_code,
            "patientMobileNo"			: data.mobile,
            "patientMarryCode"			: data.marry_code, 
            "patientEducaCode"			: data.edu_code, 
            "patientOccupCode" 			: data.pri_code, 
            "patientSOccpCode"			: data.sec_code, 
            "patientBloodCode"			: data.blood_code,
            "patientAllergyDescription"			: data.drug_allergy,
            "patientNationCode"			: data.nation_code, 
            "patientRaceCode"			: data.race_code, 
            "patientReligionCode"			: data.reg_code, 
            "personalContractForename"		: data.emergency_fname,
            "personalContractSurname"			: data.emergency_lname,
            "personalContractMobileNo"		: data.emergency_call
        }
        const ho = await axios.post(`http://10.87.252.91:3000/api/ho-visit-register`,postData);
        //console.log(ho.data[0][""]);
        return ho;
    }
}

export const updateNewPatientAction = (payload:NewPatient)=>{
    return async (dispatch:any,store: any)=>{
        if(typeof payload.photo === "object"){
            const data = new FormData();
            data.append('photo',payload.photo[0]);
            const photo = await axios.post(`${apiPath}/new-patient-photo/`,data);
            payload.photo = photo.data.photo_name_rid;
        }
        if(typeof payload.photo2 === "object"){
            const data = new FormData();
            data.append('photo2',payload.photo2[0]);
            const photo2 = await axios.post(`${apiPath}/new-patient-photo/`,data);
            payload.photo2 = photo2.data.photo_name_pic;
        }
        const value =  {
            ...store().newPatientReducer.newPatient,
            ...payload
        }
        const newPatient = await axios.post<NewPatient>(`${apiPath}/new-patient/`,value);
        
        dispatch(update(value));
    }
}