export interface CareerPrimary{
    id: number;
    code: string;
    name_eng: string;
    name_tha: string;
}

export interface CareerPrimaryState{
    careerPrimarys: CareerPrimary[],
}

export const CAREERPRIMARYLIST = "CAREERPRIMARYLIST";

interface ListAction{
    type: typeof CAREERPRIMARYLIST,
    payload: CareerPrimary[]
}

export type CareerPrimaryActionType = ListAction;

