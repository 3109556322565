export interface Amper{
    id?: number;
    code?: string;
    name_eng?: string;
    name_tha?: string;
}

export const LIST_AMPER = 'LIST_AMPER';

export interface AmperState{
    ampers: Amper[]
} 

interface ListAmperAction{
    type: typeof LIST_AMPER;
    payload: Amper[]
}

export type AmperActionType = ListAmperAction;