import React,{useState,useEffect} from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { apiPath, host } from '../config/api';
import axios from 'axios';
const QrCode = () => {
    const params = useParams<{id: string}>();
    const [patientData,setPatientData] = useState<any[]>([]);
    const getNewPatient = async () => {
        const newPatient = await axios.get<any>(`${apiPath}/new-patient/?gen_code=${params.id}`);
        setPatientData(newPatient.data);
    }
    useEffect(()=>{
        getNewPatient();
    },[]);
    
    return (
        <div>
            <div style={{display: "flex",justifyContent:"center"}}>
                <QRCode  
                //value={`${host}/registration/build/new-register-profile/${params.id}`} 
                value={`${params.id}`} 
                />
            </div>
            <div style={{textAlign: "center"}}>QR Code: {params.id}</div>
            <div style={{textAlign: "center"}}>{patientData[0] && patientData[0].prename + patientData[0].fname+' '+patientData[0].lname }</div>
        </div>
    )
} 

export default QrCode;