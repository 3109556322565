import axios from 'axios';
import { Education, LIST_EDUCATION_ACTION } from './types';
import { apiPath } from '../../config/api';
const list = (payload: Education[])=>{
    return {
        type: LIST_EDUCATION_ACTION,
        payload
    }
}

export const listEducationAction = () => {
    return async (dispatch: any)=>{
        const educations = await axios.get(`${apiPath}/education/`);
        return dispatch(list(educations.data));
    }
}   