import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { listOneNewPatientByGencodeAction } from '../store/new-patient/actions';
import { ReducerType } from '../store/types';
const NewRegisterProfile = () => {
    const params = useParams<{id: string}>();
    const dispatch = useDispatch();
    const { newPatient } = useSelector((state: ReducerType)=>state.newPatientReducer);
    useEffect(()=>{
        dispatch(listOneNewPatientByGencodeAction(params.id));
    },[]);
    if(!newPatient) return <p>loading</p>
    return (
        <div className="max-w-sm mx-auto bg-white p-6 rounded-lg shadow-lg">
            <p>ชื่อ-นามสกุล: {newPatient.prename}{newPatient.fname} {newPatient.lname}</p>
            <p>สถานะ: {
            newPatient.send_to_ho==="1"
            ?"บันทึกลงระบบ HO แล้ว"
            :"ลงทะเบียน"
        }</p>
        </div>
    );
}

export default NewRegisterProfile;

