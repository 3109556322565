import { RegionalActionType, RegionalStateType, REGIONAL_LIST_ACTION } from "./types";

const initState = {
    regionals:[],
    regional:{}
}

const reducer = (state: RegionalStateType=initState,action: RegionalActionType) => {
    switch(action.type){
        case REGIONAL_LIST_ACTION:
            return {
                ...state,
                regionals: action.payload
            }
        default:
            return state;
    }
}

export default reducer;