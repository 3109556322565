import axios from 'axios';
import { Marry, MarryActionType, MARRY_LIST_ACTION } from './types';
import { apiPath } from '../../config/api';
const list = (payload: Marry[]): MarryActionType => {
    return {
        type: MARRY_LIST_ACTION,
        payload
    }
}

export const marryListAction = () => {
    return async (dispatch: any)=>{
        const marrys = await axios.get(`${apiPath}/marry/`);
        return dispatch(list(marrys.data));
    }
}