import axios from 'axios';
import { apiPath } from '../../config/api';
import { Blood, BloodActionType, BLOOD_LIST } from "./types";

const list = (payload: Blood[]): BloodActionType=>{
    return {
        type: BLOOD_LIST,
        payload
    }
}

export const bloodListAction = ()=>{
    return async (dispatch: any)=>{
        const bloods = await axios.get(`${apiPath}/blood/`);
        dispatch(list(bloods.data));
    }
}