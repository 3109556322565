import { Button, Checkbox, Container, FormControlLabel, Paper } from "@material-ui/core";
import {useState} from 'react';
import { useDispatch } from 'react-redux';
import { agreeAcceptAction } from '../../store/agreement/actions'
const AgreeComponent = () => {
    const dispatch = useDispatch();
    const [cAgree,setCAgree] = useState(false)
    
    return (
        <Container>
            <Paper>
                <div className="p-4 bg-green-600 text-white">เงื่อนไขข้อตกลง</div>
                <div className="p-4">
                    <div className="text-size-10">
                        <p className="text-center">นโยบายข้อมูลส่วนบุคคล</p></div>
                    
                        <p className="text-center">โรงพยาบาลศรีนครินทร์ คณะแพทยศาสตร์ มหาวิทยาลัยขอนแก่น</p>
                        <p className="text-center">( Privacy Notice)</p>
   
                    <p>โรงพยาบาลศรีนครินทร์ คณะแพทยศาสตร์ มหาวิทยาลัยขอนแก่น มีนโยบายและแนวปฏิบัติต่อข้อมูลส่วนบุคคลของผู้ป่วยและผู้ใช้บริการ มีดังต่อไปนี้</p>

<p className="mt-2 font-bold">วัตถุประสงค์ในการเก็บข้อมูล</p>
<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;โรงพยาบาลศรีนครินทร์ทำการเก็บข้อมูลของผู้ป่วยและผู้มาใช้บริการโดยมีวัตถุประสงค์เพื่อใช้ในการด้านการวินิจฉัยโรคทางการแพทย์ การรักษาทางการแพทย์ การจัดการด้านสุขภาพ การเรียนการสอนของนักศึกษา และการศึกษาวิจัยหรือสถิติด้านวิทยาศาสตร์สุขภาพที่เป็นประโยชน์ต่อสาธารณะที่ไม่สามารถระบุตัวตนได้ โดยรงพยาบาลศรีนครินทร์จะไม่กระทำการใดๆ แตกต่างจากที่ระบุในวัตถุประสงค์ของการเก็บรวบรวมข้อมูล เว้นแต่
</p>
<p className="ml-4">(1)ได้แจ้งวัตถุประสงค์ใหม่ให้ผู้ใช้ทราบ</p>
<p className="ml-4">(2)เป็นกรณีที่กฎหมายกำหนด</p>
<p className="mt-2 font-bold">ข้อมูลที่จะทำการจัดเก็บ และระยะเวลาการจัดเก็บ</p>
<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;โรงพยาบาลศรีนครินทร์จำทำการเก็บข้อมูลส่วนบุคคลของท่านที่เกี่ยวข้องกับการวินิจฉัยโรงทางการแพทย์ การรักษาทางการแพทย์ การให้บริการด้านสุขภาพ การจัดการด้านสุขภาพ เช่น ชื่อ อายุ ที่อยู่ อาชีพ หมายเลขโทรศัพท์ หมายเลขบัตรประชาชน หมู่เลือด ประวัติแพ้ยา ข้อมูลด้านสุขภาพ หรือข้อมูลอื่นๆที่เกี่ยวข้อง โดยจะจัดเก็บในรูปแบบเอกสารและอิเล็กทรอนิกส์ เป็นระยะเวลา 5 ปี หรือตามที่กฎหมายหรือระเบียบ ข้อบังคับที่เกี่ยวข้องกำหนด หลังจากการเข้ารับบริการครั้งสุดท้าย</p>
<p className="mt-2 font-bold">การใช้หรือเปิดเผยข้อมูลของท่าน</p>
<p className="ml-4">1.โรงพยาบาลศรีนครินทร์จะใช้และเปิดเผยข้อมูลของท่านตามวัตถุประสงค์ในการเก็บข้อมูล และตามที่กฎหมายกำหนดไว้
</p>
<p className="ml-4">2.โรงพยาบาลศรีนครินทร์ อาจให้บุคคลหรือหน่วยงานภายในคณะแพทยศาสตร์ มหาวิทยาลัยขอนแก่น ที่มีส่วนเกี่ยวข้องในด้านการรักษาพยาบาล อาทิ แพทย์ พยาบาล ทันตแพทย์ เภสัชกร นักเทคนิคการแพทย์ นักศึกษาที่มีหน้าที่ดูแลผู้ป่วย เข้าถึงหรือใช้ข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็น เพื่อให้เป็นไปตามวัตถุประสงค์ในด้านการวินิจฉัยโรคทางการแพทย์ การให้บริการด้านสุขภาพ การรักษาทางการแพทย์ การจัดการด้านสุขภาพ และการบริการอื่นๆ ของโรงพยาบาลศรีนครินทร์ คณะแพทยศาสตร์</p>
<p className="ml-4">3.โรงพยาบาลศรีนครินทร์ อาจรวบรวม และส่งข้อมูลด้านสุขภาพของท่านเท่าที่จำเป็น ให้หน่วยงานที่มีส่วนเกี่ยวข้องกับการรักษาพยาบาล และสาธารณสุข เพื่อประโยชน์ในสิทธิการรักษาของท่าน
</p>
<p className="ml-4">4.โรงพยาบาลศรีนครินทร์ อาจรวบรวมข้อมูลส่วนบุคคลของท่านที่ได้รับมาจากแหล่งอื่นเฉพาะในกรณีที่มีความจำเป็นต่อการวินิจฉัยโรคทางการแพทย์ การรักษาทางการแพทย์ การให้บริการด้านสุขภาพ การจัดการด้านสุขภาพ ทั้งนี้เพื่อประโยชน์ในการปรับปรุงข้อมูลส่วนบุคคลของท่านให้เป็นปัจจุบัน และเพื่อปรับปรุงคุณภาพและประสิทธิภาพของการดำเนินงานหรือการให้บริการของโรงพยาบาล
</p>
<p className="ml-4">5.หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล โรงพยาบาลจะแจ้งท่านทราบและจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน</p>
<p className="ml-4">6.โรงพยาบาลศรีนครินทร์ กำหนดให้บุคคลดังต่อไปนี้ มีสิทธิขอให้เปิดเผยข้อมูลด้านสุขภาพของบุคคล</p>
<p className="ml-10">(1).ผู้เป็นเจ้าของข้อมูล</p>
<p className="ml-10">(2).ผู้ที่ได้รับมอบอำนาจจากผู้เป็นเจ้าของข้อมูล</p>
<p className="ml-10">(3).ผู้ที่ได้รับความยินยอมจากผู้เป็นเจ้าของข้อมูล</p>
<p className="ml-10">(4).ผู้มีอำนาจการะทำการแทนเจ้าของข้อมูล คือ ผู้แทนโดยชอบธรรม กรณีเจ้าของข้อมูลเป็นเด็กหรือผู้เยาว์ ผู้อนุบาล กรฯเจ้าของข้อมูลเป็นผู้ไร้ความสมารถหรือผู้พิทักษ์ กรณีเจ้าของข้อมูลเป็น คนเสมือนไร้ความสามารถ ทั้งนี้ถ้าผู้เยาว์อายุ ๑๕ ปีบริบูรณ์แล้วต้องได้รับความยินยอมจากผู้เยาว์นั้นก่อน
</p>
<p className="ml-10">(5).ทายาท ในกรณีผู้เป็นเจ้าของข้อมูลเสียชีวิต ทายาท (๕) หมายถึง สามีภรรยาโดยชอบด้วยกฎหมาย บุตรหรือผู้สืบสันดานตามความเป็นจริง บุตรบุญธรรมตามกฎหมาย บิดาหรือมารดาตามความเป็นจริง</p>


<p className="ml-4">7.บุคคลดังต่อไปนี้มีอำนาจขอให้โรงพยาบาลศรีนครินทร์ เปิดเผยข้อมูลของท่าน โดยไม่จำเป็นต้องได้รับความยินยอมจากเจ้าของข้อมูลหรือผู้มีอำนาจกระทำการคือ ศาล พนักงานสอบสวน เจ้าหน้าที่ตำรวจ คณะกรรมาธิการ คณะกรรมการ คณะอนุกรรมการและเจ้าหน้าที่ที่มีกฎหมาย ให้อำนาจในการเรียกเอกสารข้อมูลด้านสุขภาพของบุคคลได้ ทั้งนี้ต้องอยู่ภายใต้หลักการไม่เป็นการนำข้อมูลไปใช้ในทางให้เกิดความเสียหายแก่เจ้าของข้อมูลหรือทายาท
</p>
<p className="mt-2 font-bold">การรักษาความมั่นคงปลอดภัยของข้อมูล</p>
<p className="ml-4">1.โรงพยาบาลศรีนครินทร์ ตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของท่าน โรงพยาบาลศรีนครินทร์ จึงกำหนดให้มีมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสมและสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการ สูญหาย การเข้าถึง ทำลาย ใช้ แปลง แก้ไขหรือเปิด เผยข้อมูลส่วนบุคคลโดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย โดยให้เป็นไปตามระเบียบมหาวิทยาลัยขอนแก่น เรื่องมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
</p>
<p className="ml-4">2.การทำลายข้อมูลส่วนบุคคลจะต้องเป็นไปตามระเบียบที่เกี่ยวข้องของมหาวิทยาลัยขอนแก่น
</p>
<p className="ml-4">3.โรงพยาบาลศรีนครินทร์ห้ามไม่ให้เจ้าหน้าที่ นักศึกษา หรือบุคคลหนึ่งบุคคลใดนำข้อมูลส่วนบุคคลไปเปิดเผยในทางที่ก่อให้เกิดความเสียหายต่อเจ้าของข้อมูล หากเกิดความเสียหายผู้ที่นำข้อมูลไปเปิดเผยต้องรับผิดชอบตามกฎหมายที่เกี่ยวข้องทั้งหมด</p>
<p className="mt-2 font-bold">การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</p>
<p className="ml-4">1.ในกรณีที่ท่านประสงค์จะทราบข้อมูลส่วนบุคคลเกี่ยวกับตนเออง ท่านสามารถยื่นคำร้องขอตามหลักเกณฑ์และวิธีการที่โรงพยาบาลศรีนครินทร์กำหนด เมื่อโรงพยาบาลศรีนครินทร์ ได้รับคำร้องขอดังกล่าวแล้วโรงพยาบาลจะรีบดำเนินการแจ้งข้อมูลส่วนบุคคลดังกล่าวให้ท่านภายในระยะเวลาอันสมควร
</p>
<p className="ml-4">2.หากท่านเห็นว่า ข้อมูลส่วนบุคคลใดเกี่ยวกับตนไม่ถูกต้องตามที่เป็นจริงที่ระบุไว้ในข้อมูลของส่วนราชการหรือหน่วยงานที่เกี่ยวข้อง ท่านสามารถนำหลักฐานพร้อมคำรับรองความถูกต้องจากหน่วยงานดังกล่าวแจ้งต่อโรงพยาบาลเพื่อให้แก้ไขข้อมูลส่วนบุคคลนั้นได้ ในการนี้โรงพยาบาลจำทำการบันทึกการแก้ไขความถูกต้อง หรือการกระทำใดๆ เกี่ยวกับข้อมูลส่วนบุคคลของท่านไว้เป็นหลักฐานด้วย
</p>
<p className="ml-4">3.ท่านมีสิทธิตรวจดูความมีอยู่ ลักษณะของข้อมูลส่วนบุคคล วัตถุประสงค์ของการนำข้อมูลไปใช้ และสถานที่ทำการโรงพยาบาลศรีนครินทร์ นอกจากนี้ยังมีสิทธิดังต่อไปนี้</p>
<p className="ml-10">1.ขอสำเนา หรือขอสำเนารับรองถูกต้องเกี่ยวกับข้อมูลส่วนบุคคลของตน</p>
<p className="ml-10">2.ขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลที่เกี่ยวข้องกับตน</p>


<p className="ml-4">4.ในกรณีที่โรงพยาบาลศรีนครินทร์ใช้ฐานความยินยอมในการเก็บรวบรวม ใช้  หรือเปิดเผยข้อมูลส่วนบุคคลของ ท่านสามารถขอถอนความยินยอมที่ได้ให้ไว้ได้ เว้นแต่มีข้อจำกัดสิทธิ์ในการถอนความยินยอมโดยกฎหมายหรือสัญญาที่ให้ประโยชน์แก่เจ้าของข้อมูลส่วนบุคคลที่ได้ให้ความยินยอมไปแล้วโดยชอบ
</p>
<p className="mt-2 font-bold">การเชื่อมโยงข้อมูลส่วนบุคคลกับบุคคลอื่นหรือหน่วยงานอื่น</p>
<p className="ml-4">1.โรงพยาบาลศรีนครินทร์ อาจเชื่อมโยงข้อมูลส่วนบุคคลของท่านให้หน่วยงานของรัฐ หรือหน่วยงานที่มีส่วนเกี่ยวข้องกับการรักษาพยาบาล และสาธารณสุข เพื่อประโยชน์สิทธิในการรักษาของท่าน
</p>
<p className="ml-4">2.หากมีการเปลี่ยนแปลงการเชื่อมโยงข้อมูล โรงพยาบาลศรีนครินทร์ จะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงดังกล่าว</p>
<p className="mt-2 font-bold">การเปลี่ยนแปลงนโยบายส่วนบุคคล</p>
<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;โรงพยาบาลศรีนครินทร์ อาจปรับปรุงนโยบายส่วนบุคคลนี้เป็นครั้งคราวเพื่อให้สอดคล้องกับการเปลี่ยนแปลงของการให้บริการดำเนินงานของโรงพยาบาล และจะประกาศแจ้งการเปลี่ยนแปลงให้ทราบอย่างชัดเจนก่อนจะดำเนินการเปลี่ยนแลง หรืออาจส่งประกาศแจ้งเตือนให้ท่านทราบโดยตรง</p>
                </div>
                <div className="p-4"> <FormControlLabel
                    control={
                        <Checkbox
                            name="cAgree"
                            checked={cAgree}
                            onChange={(e)=>{
                                setCAgree(e.target.checked);
                            }}
                        />
                    }
                    label="ยินยอม"
                /><Button type="button" onClick={
                    () => dispatch(agreeAcceptAction())
                } variant="contained" disabled={!cAgree}>ยืนยัน</Button></div>
            </Paper>


        </Container>
    );
}

export default AgreeComponent;



