import { CareerSecondaryActionType, CAREERSECONDARYLIST, CareerSecondaryState } from "./types";

const initState = {
    careerSecondarys: []
}
const reducer = (state: CareerSecondaryState=initState,action: CareerSecondaryActionType)=>{
    switch(action.type){
        case CAREERSECONDARYLIST:
            return {
                ...state,
                careerSecondarys: action.payload
            }
        default:
            return state;
    }
}

export default reducer;