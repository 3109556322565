import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React, { ReactElement,useState,useContext } from 'react'

import { RouterContext }  from '../hooks/useRouterProvider';
import { NavLink, RouterProps } from 'react-router-dom'
import QRCode from "react-qr-code";
import { clientPath } from '../config/api';
interface Props {
    open: boolean;
    setOpen(open: boolean):void;
    redirect?: string;
    message: string;
    genCode?: string;
}

export default function DialogComponent({open,setOpen,redirect="",message,genCode}: Props): ReactElement {
    const props = useContext<any>(RouterContext);
    const handleClose = ()=>{
       
      setOpen(false);
      if(redirect!==""){
        props.history.push(redirect);
      }
      
    }
    return (
        <Dialog
        open={open}
        onClose={handleClose}
        //PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move'}} id="draggable-dialog-title">
          <div className="flex justify-between">
          <div>
          ข้อความ 
          </div>
          <div>
          <Button variant="outlined" className="rounded-md text-white p-2 bg-green-800" onClick={handleClose}>
            X Close
          </Button>
          </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
            {genCode && <QRCode 
            //value={`${host}/registration/build/new-register-profile/${genCode}`} 
            value={`${genCode}`} 
            />
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <NavLink className="rounded-md  p-2 bg-green-100" to={`${clientPath}/new-register`}>ลงทะเบียน</NavLink>
          <NavLink className="rounded-md  p-2 bg-green-100" to={`${clientPath}/`}>กลับสู่หน้าหลัก</NavLink>
        </DialogActions>
      </Dialog>
      
    )
}
