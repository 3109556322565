import { BloodState } from "../blood/types";
import { EducationActiontype,EducationStateType, LIST_EDUCATION_ACTION } from "./types";
const initState = {
    education: {},
    educations: []
}
const reducer = (state: EducationStateType=initState,action: EducationActiontype)=>{
    switch(action.type){
        case LIST_EDUCATION_ACTION:
            return {
                ...state,
                educations: action.payload
            }
        default: return state;
    }
}

export default reducer;