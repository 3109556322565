export interface Location{
    id?: number;
    name: string;
    description: string;
    code: string;
    locationurl: string;
    is_private: number;
}

export interface LocationState{
    locations: Location[]
}

export const LIST_LOCATION = "LIST_LOCATION";

interface List{
    type: typeof LIST_LOCATION,
    payload: Location[]
}

export type LocationActionType = List;