import React, { ReactElement } from 'react'
import NewRegisterFormComponent from '../components/NewRegisterFormComponent';
import { History } from 'history'
import { match }  from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReducerType } from '../store/types';
import AgreeComponent from '../components/new-register-components/AgreeComponent';
interface Params{
    id:string
}
interface Props {
    history: History,
    match: match<Params>
}

export default function NewRegister({history,match}: Props): ReactElement {
    const {accept} = useSelector((reducer: ReducerType)=>reducer.agreementReducer);
    
    return (
        <div>
            {
                accept
                ?<NewRegisterFormComponent history={history} match={match} />
                :<AgreeComponent />
            }
            
        </div>
    )
}
