import axios from 'axios';
import { apiPath } from '../../config/api'
import { Amper, AmperActionType, LIST_AMPER } from './types';

const list = (payload: Amper[]):AmperActionType=>{
    return {
        type: LIST_AMPER,
        payload
    }
}



export const listAmper = (provinceCode: string)=>{
    return async(dispatch: any)=>{
        const ampers = await axios.get<Amper[]>(`${apiPath}/amper/?province_code=${provinceCode}`);
        //const ampers = await axios.get<{data: Amper[]}>('http://localhost:8000/laravel-api/public/api/ampers');
        //dispatch(list(ampers.data.data));
        dispatch(list(ampers.data));
    }
}