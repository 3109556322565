import React, { ReactElement } from 'react'
import LoginFormComponent from '../components/LoginFormComponent'

interface Props {
    
}

export default function Login({}: Props): ReactElement {
    return (
        <div>
            <LoginFormComponent />
        </div>
    )
}
