import {TambolActionType, TambolState, TAMBOL_LIST} from './types';

const initState = {
    tambols: []
}

const reducer = (state:TambolState=initState,action: TambolActionType)=>{
    switch(action.type){
        case TAMBOL_LIST:
            return {
                ...state,
                tambols: action.payload
            }
        default:
            return state
    }
}

export default reducer;