import {useEffect,useState} from 'react';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { apiPath } from '../config/api'
import { ReducerType } from '../store/types';
import { addNewPatientAction } from '../store/new-patient/actions';

export const useOtpCode = () => {
    const [isSuccess,setIsSuccess] = useState(false);
    const [isNewOtpSuccess,setIsNewOtpSuccess] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState('');
    const dispatch = useDispatch();
    const {newPatient} = useSelector((selector: ReducerType)=> selector.newPatientReducer)
    const checkOtp = async (id: string | undefined,otpCode: string) => {
//3 4599 00259 41 7
        try{
            setIsLoading(true);
            const result = await axios.post(`${apiPath}/otp/`,{
                id,
                otp_code: otpCode
            });
            if(result.data.success){
                setError('');
                setIsSuccess(true);
            }else if(result.data.error){
                setIsSuccess(false);
                setError('ไม่พบข้อมูลกรุณาลองใหม่');
            }
            setIsLoading(false);
        }catch(error){
            setError('ไม่พบข้อมูลกรุณาลองใหม่');
            setIsLoading(false);
        }
    }
    const reSendOtp = async (id?: string) => {
        try{
            setIsLoading(true)
            setIsNewOtpSuccess(false);
            const result = await axios.get(`${apiPath}/otp/?id=${id}`);
            if(result.data.success){
                setError('');
                setIsNewOtpSuccess(true);
            }
            setIsLoading(false);
        }catch(error){
            setError("ไม่พบข้อมูล")
        }
    }
    return {checkOtp,reSendOtp,isSuccess,isNewOtpSuccess,isLoading,error};
    
}