import { LIST_PRENAME, Prename, PrenameActionType } from "./types"
import axios from 'axios';
import { apiPath } from '../../config/api';

const list = (payload: Prename[]): PrenameActionType=>{
    return {
        type: LIST_PRENAME,
        payload
    }
}

export const listPrename = ()=>{
    return async (dispatch:any)=>{
        const payload = await axios.get<Prename[]>(`${apiPath}/prename/`);
        dispatch(list(payload.data));
    }
}