export interface Blood{
    id?: number;
    code?: string;
    name_eng?: string;
    name_tha?: string;
}

export interface BloodState{
    bloods: Blood[]
}

export const BLOOD_LIST = "BLOOD_LIST";

interface ListAction{
    type: typeof BLOOD_LIST,
    payload: Blood[]
}

export type BloodActionType = ListAction;