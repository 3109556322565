import {combineReducers} from 'redux';
import {patientReducer} from './patient/reducer';
import { newPatientReducer } from './new-patient/reducer';
import { userReducer } from './users/reducer';
import prenameReducer  from './prename/reducer';
import provinceReducer from './province/reducer';
import amperReducer from './amper/reducer';
import tambolReducer from './tambol/reducer';
import locationReducer from './location/reducer';
import careerPrimaryReducer from './career-primary/reducer';
import careerSecondaryReducer from './career-secondary/reducer';
import bloodReducer from './blood/reducer';
import educationReducer from './education/reducer';
import marryReducer from './marry/reducer';
import regionalReducer from './regionals/reducer';
import nationReducer from './nations/reducer';
import raceReducer from './races/reducer';
import agreementReducer from './agreement/reducer';
import { ReducerType } from './types';
const reducers =  combineReducers({
    patientReducer,
    newPatientReducer,
    userReducer,
    prenameReducer,
    provinceReducer,
    amperReducer,
    tambolReducer,
    locationReducer,
    careerPrimaryReducer,
    careerSecondaryReducer,
    bloodReducer,
    educationReducer,
    marryReducer,
    regionalReducer,
    nationReducer,
    raceReducer,
    agreementReducer
})

export default reducers;