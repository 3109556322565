import { Link, Typography } from '@material-ui/core';
import React  from 'react';
const Footer = ()=>{
    return (
        <Typography className="text-white" align="center">
          <Link color="inherit" href="https://md.kku.ac.th/">
            ฝ่ายศูนย์ความเป็นเลิศและศูนย์กลางบริการสุขภาพ งานสารสนเทศ
            คณะแพทยศาสตร์ มหาวิทยาลัยขอนแก่น
          </Link>{' '}
          {'Copyright © '}{new Date().getFullYear()}
          {'.'}
        </Typography>
      );
}

export default Footer;