import { PatientActionTypes,PatientState,ADD_PATIENT, LIST_PATIENT, UPDATE_PATIENT, Patient, LOADING_PATIENT } from './types'

const initState: PatientState = {
    patients:[],
    loading: false
};

export const patientReducer = (state:PatientState=initState,action:PatientActionTypes):PatientState=>{
    switch(action.type){
        case ADD_PATIENT:
            let newState = {...state};
            newState.patients.push(action.payload);
            return newState;
        case LIST_PATIENT:
            return{
                ...state,
                patients: action.payload
            }
        case UPDATE_PATIENT:
            let updateState = {...state};
            const index = updateState.patients.findIndex((value=>value.id===action.payload.id))
            updateState.patients[index] = action.payload
            return updateState;
        case LOADING_PATIENT:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}
