import axios from 'axios';
import { REGIONAL_LIST_ACTION,Regional, RegionalActionType } from './types';
import { apiPath }  from '../../config/api';
import { Action } from 'redux';

const list = (payload: Regional[]) => {
    return {
        type: REGIONAL_LIST_ACTION,
        payload
    }
}

export const regionalListAction = () => {
    return async (dispatch: (arg: Action)=>void) => {
        const regionals = await axios.get(`${apiPath}/regionals/`);
        dispatch(list(regionals.data));
    }
}

